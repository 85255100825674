import React, { useEffect, useState,useRef  } from 'react'
import { NavLink,useNavigate  } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { IMAGE } from '../../common/Theme';
import GeneralLoader from '../../common/GeneralLoader';
import Nodata from "../../common/Nodata";
import { JobpostUrlAction, StepAction, JobPostAction,JobTitleAction,JobPostIdAction,GoToConcerenAction } from '../../reducer/Dataflowreducer';
import ApiConnection from '../../common/ApiConnection';
import validUrl from 'valid-url';
import { Mixpanel } from '../../auth/YsfMixPanel'; 
import Modal from 'react-bootstrap/Modal';

const JobPost = () => {

    const [jobUrl, setJobUrl] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyLinkdin, setCompanyLinkdin] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [nodata, setNodata] = useState(true);
    const [output, setOutput] = useState(false);
    const [jobpostUrlData, setJobpostUrlData] = useState("");
    const [jobPostEmptyUrl, setJobPostEmptyUrl] = useState(false);
    const [isAlreadyJobURL, setIsAlreadyJobURL] = useState("");
    
    const [btnJobpostLoaderUrl, setBtnJobpostLoaderUrl] = useState(false);        
    const [jobPostSubmitButton, setJobPostSubmitButton] = useState(false);  
    const [jobPostResponseData, setJobPostResponseData] = useState([]); 
    const [errorMessage, setErrorMessage] = useState('');  
    const [isTxtDisabled, setIsTxtDisabled] = useState(false); 
    const [showDetailsDiv, setShowDetailsDiv] = useState(false);

    const [show, setShow] = useState(false);
    const [step, setstep] = useState("");
    const [forNoLinkedinError, setForNoLinkedinError] = useState(false);
    const [forJobpostUrlError, setForJobpostUrlError] = useState(false);
    const [isJobpostUrlInput, setIsJobpostUrlInput] = useState(false);
    const [jobpostDescription, setJobpostDescription] = useState('');
    const [checkJobDescMessage, setCheckJobDescMessage] = useState('');
    const [jobDescErrorMessage, setJobDescErrorMessage] = useState('');
    const [jobDescLoading, setJobDescLoading] = useState(false);
    const [selectJobpostURL, setSelectJobpostURL] = useState(false);
    const [selectJobpostdesc, setSelectJobpostdesc] = useState(false);
    const [jobpostId, setJobpostId] = useState("");
    const [isModelGpt4o, setIsModelGpt4o] = useState("1");

    var user_email = localStorage.getItem('ysf_email');    
    var jobAppLang = localStorage.getItem('jobAppLang');
 
    const dispatch = useDispatch();
    const jobposturlRef = useRef(null);
    const jobpostDescRef = useRef(null);

    useEffect(() => {
      if (jobposturlRef.current) {
        jobposturlRef.current.focus();
        setIsTxtDisabled(false);
        setSelectJobpostURL(true); 
      }
    }, []);      

    const SelectJobpostUrlHnd = async ()=>{     
      setSelectJobpostURL(true);       
      setSelectJobpostdesc(false);   
      setIsJobpostUrlInput(false); 
      setCheckJobDescMessage(""); 
      setJobDescErrorMessage("");
    } 

    const SelectJobpostDescHnd = async ()=>{     
      setSelectJobpostURL(false);       
      setSelectJobpostdesc(true);
      setIsJobpostUrlInput(true);
      setJobUrl("");
      setErrorMessage("");
      setForJobpostUrlError(false); 
      setCheckJobDescMessage("");
    }
 
    const BtnGoodToGo = (step) => {
          dispatch(StepAction(step));
          dispatch(JobpostUrlAction(jobUrl)); 
          dispatch(JobPostIdAction(jobpostId)); 
          dispatch(GoToConcerenAction("CVFeedbackNotGenerated"));             
          Mixpanel.track('YsfJobApp:Jobpost Good to Go');
    };

    const jobUrlHandle =(value)=>{
          setErrorMessage(false);     
          setIsAlreadyJobURL(false); 
          setForNoLinkedinError(false);  
          setForJobpostUrlError(false); 
          
          if(value !== ''){
            setJobPostEmptyUrl(true);
            setJobUrl(value.trim());
           } 
          else{
            setJobPostEmptyUrl(false);
            setErrorMessage("");            
            setJobTitle("");
            setCompanyName("");
            setCompanyLinkdin(""); 
            setJobUrl(value);
            jobposturlRef.current.focus(); 
          }          
    }
      
    const jobTitleHnd =(_jobTitle)=>{       
        if(_jobTitle !== ''){
          setJobTitle(_jobTitle)
         } 
        else{     
          setJobTitle("");        
        }          
    } 

    const companyNameHnd =(_companyName)=>{       
      if(_companyName !== ''){
        setCompanyName(_companyName)
       } 
      else{     
        setCompanyName("");        
      }          
    } 

    const companylinkdinUrlHnd =(_companyLinkedinURL)=>{       
      if(_companyLinkedinURL !== ''){
        setCompanyLinkdin(_companyLinkedinURL)
      } 
        else{     
          setCompanyLinkdin("");        
        }          
    }
   
    function validLinkedinJobpostUrl(jobpostUrl) {      
      //const pattern = /^https:\/\/www\.linkedin\.com\/jobs\/\d+\/$/;
      //pattern.test(jobpostUrl)
      let isValid=jobpostUrl.includes('linkedin.com/');
      return isValid;
    }

    const JobpostUrlSubmitHandle = async ()=>{       
      jobAppLang = localStorage.getItem('jobAppLang');
 
      setForNoLinkedinError(false);
      setShowDetailsDiv(false);
      setJobPostSubmitButton(false);
      setForJobpostUrlError(false);
      setSelectJobpostdesc(false);
      setJobpostDescription("");

        if (!validUrl.isWebUri(jobUrl.trim())) {         
          setErrorMessage('Please enter valid Job Post URL!'); 
          jobposturlRef.current.focus();
          setJobTitle("");
          setCompanyName("");
          setCompanyLinkdin("");    
          setNodata(true);               
          return;
         }
        if (validLinkedinJobpostUrl(jobUrl.trim())) {                   
          setForNoLinkedinError(true);
          jobposturlRef.current.focus();
          setJobTitle("");
          setCompanyName("");
          setCompanyLinkdin(""); 
          setNodata(true);        
          return;
         }
        setErrorMessage('');        
        setJobPostSubmitButton(false);
        setBtnJobpostLoaderUrl(true);
        setJobPostEmptyUrl(false);
        
        setCompanyName("");
        setCompanyLinkdin("");
        setJobTitle("");
        setNodata(false);
        setLoading(true);    
        setOutput(false); 
        dispatch(JobPostAction());
        setIsAlreadyJobURL(false);
        try{

          // Check Validation
          
          let checkURLExistsObj = {
            'UserEmail':user_email,
            'JobPostURL' : jobUrl
          }  
          const validResponse = await ApiConnection.post("/JobbsprangetUser/JobbsprangetValidJobPostUrlApi",checkURLExistsObj);      
          if(validResponse.data.isSuccess)
          {
            setIsAlreadyJobURL(true);            
            setNodata(true);
            setBtnJobpostLoaderUrl(false);
            console.log("Already JobPost URL exist."); 
            setLoading(false);           
            return;
          }
          else
          { 
            let jobpostUrlObj = {
              'UserStage':'JOBPOSTURL',
              'JobPostURL' : jobUrl,
              'IsModelGpt4o':isModelGpt4o,              
            }  
            const response = await ApiConnection.post("/JobbsprangetUser/JobbsprangetJobPostOpenApi",jobpostUrlObj)

            if(response){
              console.log("response#"+response.data);
              if(response.data.includes('TimeOut Error: Try Again') 
                || response.data.includes('sorry')  
                || response.data.includes('but I cannot extract') 
                || response.data.includes('I applied')
                || response.data.includes('I apologize,')
                || response.data.includes('I apologize for the confusion,')
                || response.data.includes('Unfortunately,')
                || response.data.includes('Not mentioned')
                || response.data.includes('Not specified')
                || response.data.includes('The provided content')
                || response.data.includes('Not provided')
                || response.data.includes('Unknown#')
                || response.data.includes('Not provided in the given content')
                || response.data.includes('there is no specific job description ')
               )
               {
                  setForJobpostUrlError(true);
                  setBtnJobpostLoaderUrl(false);
                  setJobPostEmptyUrl(true);
                  setNodata(true);           
                  setLoading(false); 
                  setIsModelGpt4o("0");       
                  return;
                }
              setIsModelGpt4o("1");                 
              var checkValid=response.data.includes('COMPANY_NAME:');
              if(checkValid){
                setBtnJobpostLoaderUrl(false);
                setJobPostEmptyUrl(true);  
                setJobPostSubmitButton(true);
                setShowDetailsDiv(false);                
                setLoading(false);  

                var rawData = response?.data?.split('COMPANY_NAME:')[1].split('#\n\n')
                var jobTitle = (rawData[1]?.split('JOB_TITLE:')[1]).trim();
                var cname = (rawData[0]?.split('#')[0]).trim();
                var clurl = (rawData[0]?.split('#')[1]).trim();
                setCompanyLinkdin(clurl)
                setCompanyName(cname)
                setJobTitle(jobTitle);               
              
                var finalJobTxt=response?.data; 
                finalJobTxt=removeAsterisks(finalJobTxt.trim());
                finalJobTxt=removeHash(finalJobTxt.trim());
                var output = finalJobTxt.split('\n\n');
                if((finalJobTxt).includes('Certainly') || (finalJobTxt).includes('Sure')){
                  output.splice(0,1);
                }
                var finalOutput=[];
                output && output.forEach((jobTxt, index)=>{
                  if((jobTxt.trim().includes("---")) || (jobTxt.trim().includes("COMPANY_NAME:"))|| (jobTxt.trim().includes("JOB_TITLE:")))
                    {
                      console.log(jobTxt);
                    }
                    else{
                      finalOutput.push(jobTxt);
                    }                    
                }); 
                                     
                setJobPostResponseData(finalOutput);                 
                setJobpostUrlData(response?.data); 

                setTimeout(() => {
                  setShowDetailsDiv(true);
                  setNodata(true);
                }, 1000);
              } 
              else {
                setBtnJobpostLoaderUrl(false);
                setJobPostEmptyUrl(true);
                setNodata(false);           
                setLoading(false);
               
              }            
            }
          } 
        }
        catch(err){
            console.log(err)
            setBtnJobpostLoaderUrl(false);
            setJobPostEmptyUrl(true);
            setJobPostSubmitButton(false);
            setNodata(true); 
            setLoading(false);
                    
          }          
    }     
  
    const BtnSaveConfirmJobpostHandle = async () =>{      
      setNodata(false);      
      setOutput(false);
      var shortcname;
      try
          {
            if(companyName!==null)
            {
              if(companyName.split(',').length>0)
                 shortcname = companyName.split(',')[0];
               else
               shortcname = companyName;
            } 
      
            let saveJobpostObj = {            
              'UserEmail':user_email,
              'JobPostURL':jobUrl,
              'JobPostDescription':jobpostUrlData,
              'JobPostTitle':jobTitle,
              'JobPostCompanyName':companyName,
              'JobPostCompanyLikedinURL':companyLinkdin
            }
    
            let savelocalobj = {           
              'UserEmail':user_email,
              'JobPostURL':jobUrl,
              'JobPostId':jobpostId,
              'JobPostTitle':jobTitle,
              'JobPostCompanyName':companyName,
              'JobPostCompanyLikedinURL':companyLinkdin,
            } 
            const response = await ApiConnection.post("/JobbsprangetUser/JobbsprangetJobPostSaveApi",saveJobpostObj)           
            if(response.data.isSuccess){              
              setOutput(true);
              dispatch(JobTitleAction(shortcname + ' -' + jobTitle))
              dispatch(StepAction(1));
              setJobpostId(response.data.jobPostId);
              dispatch(JobPostAction(savelocalobj));
            } else {
              setLoading(false);
              setOutput(false);
              setNodata(true);
            }
      
          } catch(err){
            setLoading(false);
            setOutput(false);
            setNodata(true);
          } 
    }

    function CopyLinkHandle(){
      var copyText = document.getElementById("LinkdinUrl");
      window.open(copyText.value);      
    }

    const BtnJobpostDescription = async () =>
    {   
      jobAppLang = localStorage.getItem('jobAppLang');    
      setCheckJobDescMessage("");    
      setJobPostSubmitButton(false);
      setShowDetailsDiv(false);
      setJobDescErrorMessage(false);     
      try
         {
          if(!jobpostDescription){           
            setCheckJobDescMessage("Please Paste the Job Description here!");     
            jobpostDescRef.current.focus();
            return;
           }
            setNodata(false);
            setLoading(true);    
            setOutput(false); 
            setJobDescLoading(true);  
                        
            let jobpostUrlDescObj = {
              'JobPostURL' : jobUrl,
              'JobPostDescription':jobpostDescription.trim(),
              'IsModelGpt4o':isModelGpt4o,              
            }  
            const response = await ApiConnection.post("/JobbsprangetUser/JobbsprangetJobPostOpenApi",jobpostUrlDescObj)

            if(response){
              console.log("response#"+response.data);
              if(response.data.includes('TimeOut Error: Try Again') 
                || response.data.includes('I applied')
                || response.data.includes('I apologize,')
                || response.data.includes('I apologize for the confusion,')
                || response.data.includes('Unfortunately,')
                || response.data.includes('Not mentioned')
                || response.data.includes('Not specified')
                || response.data.includes('The provided content')
                || response.data.includes('Not provided')
                || response.data.includes('Unknown#')
                || response.data.includes('Not available in the provided content')
                || response.data.includes('Not provided in the given content')
                || response.data.includes('there is no specific job description')
               )
               {
                  setJobDescErrorMessage(true);                                                   
                  setNodata(false);           
                  setLoading(false);  
                  setJobDescLoading(false); 
                  setIsModelGpt4o("0");                 
                  return;
                }                 
              
              setIsModelGpt4o("1");  
              var checkValid=response.data.includes('COMPANY_NAME:');
              if(checkValid){
                setBtnJobpostLoaderUrl(false);
                setJobPostEmptyUrl(true);  
                setJobPostSubmitButton(true);
                setShowDetailsDiv(false);                 
                setLoading(false); 
                setJobDescLoading(true);    
                var rawData = response?.data?.split('COMPANY_NAME:')[1].split('#\n\n')
                var jobTitle = (rawData[1]?.split('JOB_TITLE:')[1]).trim();
                var cname = (rawData[0]?.split('#')[0]).trim();
                var clurl = (rawData[0]?.split('#')[1]).trim();
                setCompanyLinkdin(clurl);
                setCompanyName(cname);
                setJobTitle(jobTitle);
                
                var finalJobTxt=response?.data; 
                finalJobTxt=removeAsterisks(finalJobTxt.trim());
                finalJobTxt=removeHash(finalJobTxt.trim());
                var output = finalJobTxt.split('\n\n');
                if((finalJobTxt).includes('Certainly') || (finalJobTxt).includes('Of course!')  || (finalJobTxt).includes('Sure')){
                  output.splice(0,1);
                } 
                var finalOutput=[];
                output && output.forEach((jobTxt, index)=>{
                  if((jobTxt.trim().includes("---")) || (jobTxt.trim().includes("COMPANY_NAME:"))|| (jobTxt.trim().includes("JOB_TITLE:")))
                    {
                      console.log(jobTxt);
                    }
                    else{
                      finalOutput.push(jobTxt);
                    }  
                });

                setJobPostResponseData(finalOutput); 
                setJobpostUrlData(jobpostDescription.trim());

                setTimeout(() => {
                  setShowDetailsDiv(true);
                  setNodata(true);
                }, 1000);
              } 
              else {
                setBtnJobpostLoaderUrl(false);
                setJobPostEmptyUrl(true);
                setNodata(false);           
                setLoading(false);               
              }            
            }
          } 
          catch(err){
            setLoading(false);
            setOutput(false);
            setNodata(true);
          }
    }

    const BtnErrorPasteJobpostdesc = async () =>{      
       setIsJobpostUrlInput(true);
       setForJobpostUrlError(false);
       setCheckJobDescMessage("");
       setJobpostDescription("")
    }

    const BtnBackJobpostDescription = async () =>{      
      setIsJobpostUrlInput(false);
      !jobDescErrorMessage? setForJobpostUrlError(true):setForJobpostUrlError(false);   
    }

    const jobpostDescriptionHandle =(jobdescValue)=>{    
      console.log("HIILength#"+jobdescValue.length);
      if(jobdescValue!== ''){
        console.log("HII#"+jobdescValue);
        setCheckJobDescMessage("");
        setJobpostDescription(jobdescValue);
        setJobDescLoading(false);
        setJobDescErrorMessage(false);
       } 
      else
      {       
        console.log("HIINO#"+jobdescValue.length);
        setCheckJobDescMessage(""); 
        setErrorMessage("");    
        jobpostDescRef.current.focus(); 
        setJobpostDescription("");
        setJobDescLoading(true);
        setJobDescErrorMessage(false);
      }          
    }

    const ModalHandle = (step) => {
      setShow(true);
      setstep(step);
    }
    const removeAsterisks = (strTxt) => {
      return strTxt.replace(/\*/g, '');
    };
    const removeHash = (strTxt) => {
      return strTxt.replace(/#/g, '');
    };   
    function TryNowJobdescription(){
      setNodata(true)
      setJobDescErrorMessage(false);
    }

  return (
    <>
    <div className="row now-gutters vh-class">
    <div className="col-lg-6">
    
      <div className="common-left">
      <div className="pageHeading">
          <h1>
            <img src={IMAGE.Document} alt='Job Post' />
            Job Post
          </h1>
      </div>
      <div className="default-card">
          <div className="default-card-header">
            <span>1</span>
            <div className="default-card-header-text">
              <h3>Start your next adventure</h3>
              <p>Share your dream job details and let us support you with your ideal career opportunity!</p>
            </div>
            <div className="default-card-header-img">
              <img src={IMAGE.context_card_img} alt='' />
            </div>
          </div>
          <div className="default-card-body">
            <div className="input-field-list">
              <div className="input-field-label">
                <span>
                  <img src={IMAGE.qa_icon} alt=''  />
                </span>
                <div className="input-field-info">
                  <h4>What is the job you apply for?</h4>
                  {isJobpostUrlInput ? 
                    <p>You can insert the job details here</p> :
                    <p>You can paste the job post URL directly here:
                    </p>
                  } 
                </div>        
              </div>         

               <div className="form-group text-center mt-2 mb-3 job-post-tab-button">                   
                  <button  onClick={SelectJobpostUrlHnd}  className={!isJobpostUrlInput ? 'active hover-btn' : 'hover-btn'}>Enter JobPost URL </button>                                 
                 
                   <button onClick={SelectJobpostDescHnd}  className={isJobpostUrlInput ? 'active hover-btn' : 'hover-btn'}>Enter JobPost Description </button>  
              </div> 

              {!isJobpostUrlInput ? 
                  <>
                  <div className="linkdinUrl">
                      <input type="url" maxLength={250} ref={jobposturlRef} autoFocus className="form-control" placeholder="Insert your job post URL"
                        value={jobUrl} onChange={(e) => jobUrlHandle(e.target.value)} />
                      <button onClick={JobpostUrlSubmitHandle} disabled={jobPostEmptyUrl ? false : true} className='hover-btn'>Submit {btnJobpostLoaderUrl && <span class="btnloader"></span>}</button>
                    </div> 
                    </>                 
                  :
                  <>
                  <div className="jobsinput-field job-dexcription-field">
                    <label>Job Description</label>
                    <textarea  className="form-control" placeholder="Paste the Job Description here" rows="8" maxLength={12000}  value={jobpostDescription} ref={jobpostDescRef} autoFocus  onChange={(e)=>jobpostDescriptionHandle(e.target.value)}></textarea>
                  </div>
                <div className='text-end'>                    
                      <button className='backBtn' onClick={BtnBackJobpostDescription} style={{display: (selectJobpostdesc) ? 'none' : 'block'}}>
                          <img src={IMAGE.left_arrow} alt=''/>
                      </button>
                      { 
                    checkJobDescMessage &&              
                    <p className='errorurlMsg mb-2'>{checkJobDescMessage}</p> 
                    }
                    <button className='themeBtn hover-btn' onClick={BtnJobpostDescription} disabled={jobDescLoading ? true : false}>Submit </button>                    
                </div>               
                </>
              }
            </div>
            
            { errorMessage &&              
                <p className='errorurlMsg'>{errorMessage}</p> 
            }

              { forNoLinkedinError && 
                  <>
                  <p className='errorurlMsg'>LinkedIn job URL is not supported yet. No problem though! Here's a quick guide to find the right link.</p>       
                    <div className='row mt-3'>
                          <div className='col-lg-4'>
                              <div className='step-box' onClick={()=>ModalHandle('stepone')}>
                                <div className='step-box_img'>
                                    <img src={IMAGE.step_one} alt=''/>
                                  </div>
                                  <h4><b>Step 1:</b> Click “Apply” button on your LinkedIn Job Post</h4>
                              </div>
                          </div>
                          <div className='col-lg-4'>
                              <div className='step-box' onClick={()=>ModalHandle('steptwo')}>
                                <div className='step-box_img'>
                                    <img src={IMAGE.step_two} alt=''/>
                                  </div>
                                  <h4><b>Step 2:</b> Delete all text after the letter “?” if there is any</h4>
                              </div>
                          </div>
                          <div className='col-lg-4'>
                              <div className='step-box' onClick={()=>ModalHandle('stepthree')}>
                                <div className='step-box_img'>
                                    <img src={IMAGE.step_three} alt=''/>
                                  </div>
                                  <h4><b>Step 3:</b> Copy the link and paste in the field above</h4>
                              </div>
                          </div>
                    </div>
                  </>
              }
            {
              forJobpostUrlError && 
              <>
              <h5 className='wrongposturltext'>Oops! It seems this website is blocking us from automatically retrieving the job description. If the issue persists, please <button onClick={BtnErrorPasteJobpostdesc}>Click here & paste the job description</button>.</h5>
              </>
            }  

            {/* {
              jobDescErrorMessage && 
              <>
              <h5 className='wrongposturltext'>Oops! It seems the job description is not complete. Please paste the job description again.<br /><b><i>Please Click on Submit Button.</i></b> </h5>
              </>
            }  */}

            {/* { 
             checkJobDescMessage &&              
             <p className='errorurlMsg'>{checkJobDescMessage}</p> 
            }       */}

            { isAlreadyJobURL &&              
              <p style={{fontWeight:'Medium',color:'green'}}>You already applied this Job. Please check here! <NavLink to="/home"><i className="fa-solid fa-arrow-right"></i> My job list</NavLink>  </p> 
            }           

            {jobPostSubmitButton &&
             <div>
              <h4 className='specialTxt'>Splendid! Please ensure the details below are correct</h4>              
             </div>
            }

           { jobPostSubmitButton && showDetailsDiv &&
            <div>
                <div className="input-field-list mb-3">
                        <div className="input-field-label">
                          <span>
                            <img src={IMAGE.qa_icon} alt='' />
                          </span>
                          <div className="input-field-info">
                            <h4>Check the company details here</h4>
                            <p>You can check and correct the company details here
                            </p>
                          </div>
                        </div>

                        <div className="jobsinput-field">
                          <label>Job Title</label>
                          <input type="text" maxLength={100} 
                            className="form-control"
                            placeholder="The detail will appear here"
                            value={jobTitle}
                            onChange={(e) => jobTitleHnd(e.target.value)} />
                        </div>

                        <div className="jobsinput-field">
                          <label>Company Name</label>
                          <input type="text" maxLength={100} 
                            className="form-control" placeholder="The detail will appear here"
                            value={companyName}
                            onChange={(e) => companyNameHnd(e.target.value)} />
                        </div>

                        <div className="jobsinput-field">
                          <label>Company LinkedIn</label>
                          <div className="linkdinUrl">
                            <input type="url" maxLength={550} 
                            id="LinkdinUrl"
                              className="form-control"
                              placeholder="The detail will appear here"
                              value={companyLinkdin}
                              onChange={(e) => companylinkdinUrlHnd(e.target.value)} />  
                        
                              <button className='clipboard_icon' onClick={CopyLinkHandle}>
                                <img src={IMAGE.clipboard} alt=''/>
                                </button>                                           
                          </div>
                        </div>
                        
                        {/* {!companyLinkdin && 
                          <div className="jobsinput-field">
                            <label></label>
                            <div className="checkbox-wrapper">                      
                                <input type="checkbox" />
                                <span> No LinkedIn URL seems to be associated with this company.</span>                      
                          </div>
                          </div> 
                        }  */}

                </div>
                <div className='text-end'>
                      <button className='themeBtn hover-btn' onClick={BtnSaveConfirmJobpostHandle} disabled={loading ? true : false}>Confirm </button>
                </div> 
            </div> 
           } 
         </div>      
      </div>
      </div>
    </div>
    <div className="col-lg-6 p-0">
      <div className="comonn-right jobpostview">
    {loading && 
    <GeneralLoader/>      
    } 
    {nodata &&         
      <Nodata
      image={IMAGE.YSF_generate_icon}
      text="This is where you find your CV feedback and improvement suggestions "
    />
    }
    
    {output && 
       <>
     
      <div className="output-view for-jobpost">
      <div className="output-view-top">
            <h4>
              <img src={IMAGE.union_icon} alt='' /> The key point of the role and company
            </h4>
          </div>
          <div className='output-view-body'>
            {jobPostResponseData && jobPostResponseData.map((text, i)=>{
              return (                
                <p key={i}>
                  {text.trim()}
                </p>
              )
            })}
          </div>
      </div>
        <div className='go_btn'>
          <button  onClick={() => BtnGoodToGo(2)}
          className="themeBtn  hover-btn" >
          Good to go <i className="fa-solid fa-arrow-right"></i>       
          </button>
        </div>      
      </>
    } 

    { jobDescErrorMessage && 
      <div className="noresultFound" style={{width:'72%'}}>
        <img src={IMAGE.alert_icon} alt="" />
        <div className="noresultFoundInner">
        <h4>Results not generated</h4>
        <p>Oops!!! <br />It seems the job description is not complete.<br /> Please give proper description.</p>
        </div>
        <button
        className="tryBtn"
        onClick={()=>TryNowJobdescription(true)}> 
        Try Now
        </button>
        </div>
    }    

    <div className="bottom-navigation"> 
    </div>
       
    </div>
    </div>
    </div>
    <div className='concern-footer'>
        <NavLink to="/home"><i className="fa-solid fa-arrow-left"></i> My job list</NavLink>       
    </div>

    <Modal show={show} className='big-modal'>
      <button className='close_btn' onClick={()=>setShow(false)}><img src={IMAGE.white_close} alt='' /> </button>     
      <div className='big_img'>
            {step === 'stepone' && 
              <img src={IMAGE.step_one_big} alt='' />
            }
            {step === 'steptwo' && 
            <img src={IMAGE.step_one_big} alt='' />
            }

            {step === 'stepthree' && 
            <img src={IMAGE.step_one_big} alt='' />
            }
      </div> 
        {step === 'stepone' && 
          <h4>Step 1: Click “Apply” button on your LinkedIn Job Post</h4>
        }
        {step === 'steptwo' && 
        <h4>Step 2: Delete all text after the letter “?” if there is any</h4>
        }
        {step === 'stepthree' && 
        <h4>Step 3: Copy the link and paste in the field above</h4>
        }
    </Modal>
    </>
  )
}
export default JobPost