import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import  dataflowreducer  from '../reducer/Dataflowreducer';
import { combineReducers } from 'redux'



const persistConfig = {
    key: 'root',
    storage,
  }

  const rootReducer = combineReducers({ 
    dataflowreducer: dataflowreducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const Store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(Store)