import React from 'react'

const DefaultLoader = ({toggle}) => {
  return (
    <div className= {!toggle ? 'concerndefaultloader' : 'concerndefaultloader Toggle'}>
        <div className='loader-list'>
            <div className='loader-skelen-square'></div>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
        </div>
        <div className='loader-list'>
            <div className='loader-skelen-square'></div>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
        </div>
        <div className='loader-list'>
            <div className='loader-skelen-square'></div>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
        </div>
        <div className='loader-list'>
            <div className='loader-skelen-square'></div>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
        </div>
        <div className='loader-list'>
            <div className='loader-skelen-square'></div>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
        </div>
        <div className='loader-list'>
            <div className='loader-skelen-square'></div>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
        </div>
    </div>
  )
}

export default DefaultLoader