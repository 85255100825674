import React from 'react';
import { useNavigate } from "react-router-dom";
import { IMAGE } from '../common/Theme'

const SubscriptionExpired = () => { 
  let navigate = useNavigate()
  
  return (
    <div className="common-panel home-page subscription-trail" >
    <div className="home-container">
       <div className='subscription-container '>                
       <div className='row align-items-center'>
           <div className='col-lg-6'>
           <img src={IMAGE.expire_alert_icon} alt='' className='w-100' />                 
           </div>
           <div className='col-lg-6 text-center'>
           <img src={IMAGE.alert_img}  alt='' />
                   <h3>Your trial expired!</h3> 
                   <p>Don't miss out! Upgrade now to keep pursuing <br></br> your dream job!</p>                       
                   <button className="BtnStyle_two" 
                   onClick={()=>{navigate("/subscription")                        

                   }}>Upgrade Now</button>
           </div>
         </div>
       </div> 
   </div>
</div>
    )
  }

export default SubscriptionExpired