import React, { useState, useEffect} from "react";
import {Await, NavLink, useNavigate, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { IMAGE } from "../common/Theme";
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import ApiConnection from "../common/ApiConnection";
import { Mixpanel } from '../auth/YsfMixPanel';  

const Onboard = () => {
    const [show, setShow] = useState(false);
    const [LanguageModalShow, setLanguageModalShow] = useState(false);
    const [language, setLanguage] = useState({img:IMAGE.UK, lan:'English'});   
    
    const [animate,setanimate] = useState(false);
    let navigate=useNavigate();
    const [slider, setslider] = useState([
      {
        id:1,
        text:"Smart decision!",
        para:"Now you’re one step closer to you dream job. Let’s see how can we start from here.",
        image:IMAGE.onboard_one
      },
      {
        id:2,
        text:"First, let’s review your CV",
        para:"Simply upload your CV and get instant feedback on how to improve your CV to match the job!",
        image:IMAGE.onboard_two
      },
      {
        id:3,
        text:"Come prepared!",
        para:"We generate possible interview questions and advice how to answer them ! How great!",
        image:IMAGE.onboard_three
      },
      {
        id:4,
        text:"Nail your first 20 seconds!",
        para:"We generate the introduction based on your profile and the job requirements.",
        image:IMAGE.onboard_four
      },
    ]);
    const [index, setindex] = useState(0);
    const {state} = useLocation(); 
    //console.log("state#"+state);
    //console.log("state##"+state?.user_email)  

   const LogoutHandle = () => {
    localStorage.clear();
    window.location.reload();
  }

  const handleLanguageChange = (img, lan) => {
    const selectedLanguage = { img, lan };
    setLanguage(selectedLanguage);
 
  };

   const OnboardHandle = async () => {
    try
    {
      let body = {
        UserEmail:state?.user_email
      }
    
      const response = await ApiConnection.post("/JobbsprangetUser/Onboarding", body)

      if(response.data?.isSuccess){
        localStorage.setItem('ysf_user', state?.user_name);
        localStorage.setItem('ysf_email', state?.user_email);
        localStorage.setItem('fname', state?.first_name);
        localStorage.setItem('jobAppLang', language.lan); 
        localStorage.setItem('DaysLeft', state?.DaysLeft);
        localStorage.setItem('license', state?.license);
        localStorage.setItem('subscriptionId', state?.subscriptionId);
        window.location.reload();
        //navigate("/subscription");
      }
    } catch(err){
      console.log("Error");
    }
    finally{
      Mixpanel.identify(state?.user_email);                       
      Mixpanel.people.set({
          $first_name: state?.user_name,
          $email: state?.user_email
      });
      Mixpanel.track('YsfJobApp:Onboarding');     
    }    
  }


   useEffect(()=>{
    try{
      setTimeout(()=>{
        setanimate(true);
      },1000);      
    }
    catch (err) {
      console.log("error!");      
    }   
    finally{
      console.log("finally!");  
    } 
   },[index]);

  return (
    <div className="onboard-page">
          <div className='header'>
    <div className='container-fluid'>
      <div className='row align-items-center'>
      <div className='col-lg-4 col-5'>
        <NavLink to="/">
        <img src={IMAGE.logo_svg} alt='logo'  className='logo' />
        </NavLink>       
      </div>
      <div className='col-lg-8 col-7'>
          <div className='header-right'>
          <div id="userInfo" className='header-right'>             
              <label>Welcome, {state?.first_name}</label>
              <Dropdown className='header-dropdown'>
                  <Dropdown.Toggle  id="dropdown-basic">                    
                      <span>{(state?.user_name)?.substring(0,2)}</span>
                  </Dropdown.Toggle>
 
                  <Dropdown.Menu>
                      <div className='dopdown-top'>
                          <h5>{state?.user_name}</h5>
                          <span>{(state?.user_name)?.substring(0,2)}</span>
                      </div>
                      <ul>
                        <li><a href='https://www.yourspeechfactory.com/support' alt='Support'  target="_blank" rel="noopener">Support</a></li>
                        <li><a href="https://www.yourspeechfactory.com/about" alt='about' target="_blank" rel="noopener">About</a></li>
                        <li><button className='signOutBtn' onClick={()=>setShow(true)}>Sign Out</button></li>
                        
                      </ul>
                  </Dropdown.Menu>
                </Dropdown>
             
          </div>
      </div>
      </div>
    </div>
    </div>
    <Modal
    show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className='logout-modal text-center p-5'>
        <h4>Are you sure to log out</h4>
        <ul>
          <li><button className='btn  btn-primary px-4' onClick={LogoutHandle}>Yes</button></li>
          <li><button className='btn  btn-outline-danger px-4' onClick={()=>setShow(false)}>No</button></li>
        </ul>
         <button className="close_Icon" onClick={()=>setShow(false)}><img src={IMAGE.close_icon} alt=''/></button>
      </Modal.Body>
  
    </Modal>
    <Modal show={LanguageModalShow} size="lg" aria-labelledby="contained-modal-title-vcenter"  className='subscription-modal language_setup_modal'    centered>
      <Modal.Body className=' text-center'>
    
         <h4>Select default output language</h4>
         <p>Select your preferred language for your generated results. <br></br> You can always modify it later.</p>
         <ul>
          <li className={language.lan === 'English'? 'active' : ''}>
              <button onClick={()=>handleLanguageChange(IMAGE.UK,  'English')}>
                  <img src={IMAGE.uk_img} alt="UK" title='English' />
              </button>
          </li>
          <li className={language.lan === 'Swedish'? 'active' : ''}>
              <button  onClick={()=>handleLanguageChange(IMAGE.Sweden, 'Swedish')}>
                  <img src={IMAGE.Sweden_img} alt="Sweden" title='Swedish'/>
              </button>
          </li>
          <li className={language.lan === 'Italian'? 'active' : ''} >
              <button onClick={()=>handleLanguageChange(IMAGE.Italy,  'Italian')}>
                  <img src={IMAGE.Italian_img} alt="Italian" title='Italian' />
              </button>
          </li>
         </ul>
         <button onClick={OnboardHandle} className="BtnStyle_two mt-5 mb-4">Set Language</button>
          <h6>Note: This will not affect the interface language. </h6>
      </Modal.Body>  
    </Modal>
</div>
<div className="onboard-container-outer">
    <div className="onboard-container">
      <div className="onboard-container-small_lft"></div>
        <div className="onboard-container-big">

              <div className="onboard_slider">
                  <img src={slider[index].image} alt="onboard"/>
                 <h4>{slider[index].text}</h4>
                <p>{slider[index].para}</p>
                {index === slider.length -1 ? 
                 <button onClick={()=>setLanguageModalShow(true)} style={{opacity:animate ? 1 : 0,  transition: '.3s ease-in-out',}} className="nextBtn hover-btn">OK, let's start! <i className="fa-solid fa-arrow-right"></i></button>
                  :
                  
                  <button style={{opacity:animate ? 1 : 0,  transition: '.3s ease-in-out'}} onClick={()=>(setindex(index + 1), setanimate(false))} className="nextBtn hover-btn">Next <i className="fa-solid fa-arrow-right"></i></button>
                  
              }
                {index !== 0 &&
                <button onClick={()=>setindex(index - 1)} className="prevBtn"><i className="fa-solid fa-arrow-left"></i> Back</button>
              }
                </div>
                <div className="slider_dots">
              <ul>
                  {slider.map((slider, i)=>{
                    return (
                      <li key={i} className={i === index ? "active" : ''}><span></span></li>
                    )
                  })}
              </ul>
    </div>
        </div>
        <div className="onboard-container-small_rht"></div>
    </div>
    </div>
    
    <ul >

    </ul>
</div>  
  )
}

export default Onboard