import mixpanel from 'mixpanel-browser';
mixpanel.init('24b2f065c71a236e2eb104f2048a7a5d', { debug: false });
let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
     mixpanel.alias(id);
  },
  track: (name, props) => {
     mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
       mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;