import React,{useState, useEffect} from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import { IMAGE } from '../common/Theme'
import ApiConnection from '../common/ApiConnection';
const Success = () => {
  const [sessionId, setSessionId] = useState(null);
  let navigate = useNavigate();  
  const location = useLocation();
  var user_email=localStorage.getItem('ysf_email');
  const queryParams = new URLSearchParams(location.search);
  var _sessionId = queryParams.get('session_id'); 
  console.log("_sessionId"+_sessionId);
  if(_sessionId){
   _sessionId=(_sessionId.replace('{','')).replace('}','');  
  }

  useEffect(() => {
    setSessionId(_sessionId); 
    if(_sessionId){          
        localStorage.removeItem('DaysLeft');
        localStorage.removeItem('license');
        UserPayment();     
      }    
  }, [_sessionId]); 

  const UserPayment =  async ()=>{
    try
    { 
       let sessionObj = {UserEmail:user_email, SessionId:_sessionId}            
       const response = await ApiConnection.post("/api/JobbsprangetPayment/retrieve-subscription", sessionObj);             
       console.log(response);       
       //console.log("responseData"+response.data);       
       let subscriptionId=response.data.id;
       console.log("subscriptionId"+response.data.id);  
       console.log("custId#"+response.data.custId);
       
       let paymentObj = {          
          UserEmail:user_email,
          SessionId:_sessionId,
          SubscriptionID:subscriptionId //"cs_test_a1pq94fB3deN4OsxjJuRiOGBS2VzwIz5NwnNhFwWqeLx1yhBdN1S2Swz4Q" 
       }
      //console.log("sessionId###"+paymentObj);
      await ApiConnection.post("/api/JobbsprangetPayment/SubscriptionUpdate", paymentObj);     
    } 
    catch(e){
        console.log("Error!");
    }                      
  }
  const LetsExplore = (step) => { 
    localStorage.removeItem('JobApp_ReferId');       
    navigate("/home"); 
    window.location.reload();   
  };
  return (
    <div className="common-panel home-page success-box" >
    <div className="home-container">
        <div className='subscription-container '>
            <div className='row align-items-center'>
                <div className='col-lg-6'>
                        <img src={IMAGE.success_img} alt='' className='w-100' />
                </div>
                <div className='col-lg-6 text-center'>
                        <img src={IMAGE.success_icon} alt=''  />
                        <h3>You're all set!</h3>                       
                        <p>Subscription confirmed! You're on your way to <br></br> greatness! Welcome to the first step in landing <br></br> your dream job.</p>
                        <button className="BtnStyle_two" 
                       
                        onClick={() => LetsExplore()}
                        >Let's explore!</button>                 
                </div>
            </div> 
        </div>
    </div>
   </div>
  )
}

export default Success