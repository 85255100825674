import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { IMAGE } from '../common/Theme';

const Unsubscribe = () => {  
 let navigate = useNavigate();  
  return (
    <div className="common-panel home-page success-box" >
    <div className="home-container">
        <div className='subscription-container '>
            <div className='row align-items-center'>
                <div className='col-lg-6'>
                        <img src={IMAGE.cancel_subscription_img} alt='' className='w-100' />
                </div>
                <div className='col-lg-6 text-center'>
                        <img src={IMAGE.smile_icon} alt=''  />
                        <h3>Are you sure?</h3>                       
                        <p>By unsubscribing, you will stop receiving support for <br></br> your job search. Do you wish to proceed?</p>
                      
                      <ul className='success-box_btn'>
                        <li>
                            <NavLink className="BtnStyle_one" to="/Unsubscribe">Unsubscribe</NavLink>               
                        </li>
                        <li>
                        <button className="BtnStyle_two" onClick={()=>{
                          navigate("/ConfirmCancelSubscription")
                        }}>Stay</button>
                        </li>
                      </ul>
                </div>
            </div> 
        </div>
    </div>
   </div>
    )
  }
export default Unsubscribe