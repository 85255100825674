import React from 'react';
import { NavLink } from 'react-router-dom';
import { IMAGE } from '../common/Theme';
const Cancel = () => {  
  return (
    <div className="common-panel home-page success-box" >
    <div className="home-container">
        <div className='subscription-container '>
            <div className='row align-items-center'>
                <div className='col-lg-6'>
                        <img src={IMAGE.onboard_three} alt='' className='w-100' />
                </div>
                <div className='col-lg-6 text-center'>
                        {/* <img src={IMAGE.smile_icon} alt=''  /> */}
                        <h3>Subscribe new plan!</h3>                       
                        <p>Stay excellent, keep your CV standing out and your <br></br> interviews flawless.</p>
                      
                      <ul className='success-box_btn'>                        
                        <li>
                            <NavLink className="BtnStyle_two" to="/home">Close</NavLink>                                
                        </li>
                      </ul> 
                </div>
            </div> 
        </div>
    </div>
   </div>
  )
}
export default Cancel;