import React, { useEffect, useState } from "react"
import { IMAGE } from "../../common/Theme"
import { NavLink, useNavigate } from "react-router-dom"
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import ApiConnection from "../../common/ApiConnection";
import Nav from 'react-bootstrap/Nav';
import { useDispatch } from "react-redux";
import { StepAction,  
        JobTitleAction,JobpostUrlAction,JobPostIdAction,
        GoToConcerenAction, 
        JobInterviewPreparationDone,resetState 
        } from "../../reducer/Dataflowreducer";

import { Mixpanel } from '../../auth/YsfMixPanel';        

const Home = () =>{
    const [blankModalShow, setblankModalShow] = useState(false);
    const [cvUploadDoneModalShow, setcvUploadDoneModalShow] = useState(false);
   
    const [isJobList, setIsJobList] = useState(false);    
    const [joblist, setJoblist] = useState([]);    
    const [deleteJoblist, setDeleteJoblist] = useState([]);
  
    const [jobPostId, setJobPostId] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [jobCompany, setJobCompany] = useState("");
    const [jobPostUrl, setJobPostUrl] = useState("");
    const [isCVUpload, setIsCVUpload] = useState(false);
    const [isInterview, setIsinterview] = useState(false);   
    const [showJobpost, setShowJobpost] = useState(false); 
    const [reteriveJobpost, setReteriveJobpost] = useState(false); 
    const [expireModalShow, setExpireModalShow] = useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();
    var user_name = localStorage.getItem('ysf_name');
    var user_fname = localStorage.getItem('fname');
    var user_email = localStorage.getItem('ysf_email');
    var license= localStorage.getItem('license');  
    useEffect(()=>{      
        const getData = async ()=>{
                try {
                    let getJobListObj = {                  
                        'UserEmail':user_email
                    }
                    const response = await ApiConnection.post("/JobbsprangetUser/JobbsprangetJobPostListApi", getJobListObj);

                    if(response.data?.isSuccess && response.data?.jobPostList){
                        var Rawdata = response.data?.jobPostList;
                        var spilitData = Rawdata?.split("$$$$");
                        //console.log("Array length:   ", spilitData);
                        var TempArray = [];
                        var DeleteTempArray = [];
                        spilitData.splice(spilitData?.length - 1, 1);
                        spilitData?.forEach(element => {
                            var cdata = element.split("|");
                            if(cdata[7]==="0"){  
                                //console.log("cdata[7]#"+cdata[7]);                      
                                TempArray.push(
                                    {
                                        rid:cdata[0],
                                        cname:cdata[1],
                                        jtitle:cdata[2],
                                        date:cdata[3],
                                        cvsttus:cdata[4],
                                        interviewstatus:cdata[5],
                                        JobPostlink:cdata[6],
                                        JobPostDeleted:cdata[7],
                                        JobPostDeletedDate:cdata[8]
                                    }
                                )
                                //ALL JOBLIST 
                                setIsJobList(true);                       

                            }
                            else
                            {
                                //console.log("Delete cdata[7]#"+cdata[7]);                      
                                DeleteTempArray.push(
                                    {
                                        rid:cdata[0],
                                        cname:cdata[1],
                                        jtitle:cdata[2],
                                        date:cdata[3],
                                        cvsttus:cdata[4],
                                        interviewstatus:cdata[5],
                                        JobPostlink:cdata[6],
                                        JobPostDeleted:cdata[7],
                                        JobPostDeletedDate:cdata[8]
                                    }
                                )
                                setIsJobList(true);  
                            }
                        });                    
                        setJoblist(TempArray)
                        setDeleteJoblist(DeleteTempArray);
                        //console.log(response.data);
                    }

                } catch(error){
                    console.log("Error in loading Joblist");
                }
                finally{  
                    dispatch(resetState());
                    Mixpanel.identify(user_email); 
                    if(user_name) 
                    {
                        Mixpanel.people.set({
                            $first_name:user_name,
                            $email: user_email
                        });
                    } 
                    else
                    {                           
                        Mixpanel.people.set({
                            $first_name:user_fname,
                            $email: user_email
                        });
                }                
                }
        }
        getData();
        dispatch(JobTitleAction(`Welcome, ${user_fname}`));                     
    },[]);

    const btnBlankJobListHandle = ()=>{
        console.log("btnBlankJobListHandle");
        dispatch(StepAction(1));       
        Mixpanel.track('YsfJobApp:NewJob');        
        navigate("/step-home");
    }

    const ModalHandler = (id, title,cvsttus,interviewstatus,JobPostlink,cname) => {
     
        cvsttus==='Not Started'? setIsCVUpload(false):setIsCVUpload(true);       
        interviewstatus==='Not Started'? setIsinterview(false):setIsinterview(true); 
        setShowJobpost(false); 
        setcvUploadDoneModalShow(true);          
                          
        setJobPostId(id);
        setJobTitle(title);
        setJobCompany(cname);
        setJobPostUrl(JobPostlink);               
    } 
    
    const ShowDeleteJobpost = (JobId) => {
        console.log("ShowDeleteJobpost");
        setcvUploadDoneModalShow(false);  
        setShowJobpost(true);    
        setJobPostId(JobId);       ;
        console.log("JobId#true");                    
    } 

    const DeleteJobpost = async(JobId) => {       
        console.log("DeleteJobpost");  
        setShowJobpost(false);                 
        setJobPostId(JobId);       ;
        console.log("JobId##"+jobPostId);   

        let deleteJobpostObj = {                  
            'UserEmail':user_email,
            'JobPostId':jobPostId
        }
       var response=await ApiConnection.post("/JobbsprangetUser/JobbsprangetDeleteJobPostApi", deleteJobpostObj);                     
       if(response){
          window.location.reload();
       }         
    }

    const ShowReteriveJobpost = (JobId) => {
        console.log("ShowReteriveJobpost");
        setcvUploadDoneModalShow(false);  
        setReteriveJobpost(true);    
        setJobPostId(JobId);       ;
        console.log("JobId#true");                    
    }

    const ReteriveJobpost = async(JobId) => {       
        console.log("ReteriveJobpost");  
        setReteriveJobpost(false);                 
        setJobPostId(JobId);       ;
        console.log("JobId##"+jobPostId);   

        let reteriveJobpostObj = {                  
            'UserEmail':user_email,
            'JobPostId':jobPostId
        }
       var response=await ApiConnection.post("/JobbsprangetUser/JobbsprangetReteriveJobPostApi", reteriveJobpostObj);                     
       if(response){
          window.location.reload();
       }         
    }

    const btnCVJobListHandle = ()=>{
        console.log("btnJobListHandle");
        console.log("jobPostId",jobPostId);
        console.log("jobTitle",jobTitle);      
        dispatch(JobTitleAction(jobCompany+'-'+jobTitle));
        console.log(jobPostId);        
        if(!jobPostId){
            console.log("jobPostId && !isCVUpload && !isInterview"); 
            dispatch(StepAction(1));
            dispatch(GoToConcerenAction("CVFeedbackNotGenerated"));
            dispatch(JobpostUrlAction(jobPostUrl));
            dispatch(JobPostIdAction(jobPostId));
            navigate("/step-home", {state:{id:jobPostId}})  
            return;
        }
        else if(!isCVUpload){
            console.log("isCVUpload#",isCVUpload); 
            console.log("isInterview#",isInterview); 
            dispatch(StepAction(2)); 
            setIsinterview(false);
            Mixpanel.track('YsfJobApp:New CV-Upload');  
            dispatch(JobInterviewPreparationDone(false)); 
            dispatch(GoToConcerenAction("CVFeedbackNotGenerated"));
            dispatch(JobpostUrlAction(jobPostUrl));
            dispatch(JobPostIdAction(jobPostId));
            navigate("/step-home", {state:{id:jobPostId}});
            return;           
        }
        else if(jobPostId && isCVUpload){
            dispatch(StepAction(2)); 
            dispatch(JobpostUrlAction(jobPostUrl));
            dispatch(JobPostIdAction(jobPostId));
            setIsinterview(true);
            dispatch(JobInterviewPreparationDone(true));  
            navigate("/step-home", {state:{id:jobPostId}});            
            return;
        }       
       
    }

    const btnInterviewPrepHandle = ()=>{
        console.log("btnInterviewPrepHandle#");
        console.log("isCVUpload#"+isCVUpload);
        console.log("setIsinterview#"+isInterview);
        console.log(jobPostId);
        dispatch(JobTitleAction(jobCompany+'-'+jobTitle));
       
        if(!jobPostId){
            console.log("jobPostId && !isCVUpload && !isInterview"); 
            dispatch(StepAction(1));
            dispatch(JobpostUrlAction(jobPostUrl));
            dispatch(JobPostIdAction(jobPostId));
            dispatch(GoToConcerenAction("CVFeedbackNotGenerated"));
            navigate("/step-home", {state:{id:jobPostId}})  
            return;
        }
        else if(!isCVUpload){
            console.log("isCVUpload#",isCVUpload); 
            console.log("isInterview#",isInterview); 
            dispatch(StepAction(2));
            setIsinterview(false);
            Mixpanel.track('YsfJobApp:New CV-Upload');  
            dispatch(JobInterviewPreparationDone(false)); 
            dispatch(GoToConcerenAction("CVFeedbackNotGenerated"));
            dispatch(JobpostUrlAction(jobPostUrl));  
            dispatch(JobPostIdAction(jobPostId));          
            navigate("/step-home", {state:{id:jobPostId}}) 
            return;          
        }
        else if(!isInterview){
            dispatch(StepAction(2));          
            dispatch(JobpostUrlAction(jobPostUrl));
            dispatch(JobPostIdAction(jobPostId)); 
            setIsinterview(false);             
            dispatch(JobInterviewPreparationDone(false));  
            dispatch(GoToConcerenAction("FeedbackGenerated"));           
            navigate("/step-home", {state:{id:jobPostId,InterviewDone:'N',CompanyJobTitle:jobCompany+'-'+jobTitle}})
            return;
        }
        else if(isInterview){         
            dispatch(JobpostUrlAction(jobPostUrl));
            dispatch(JobPostIdAction(jobPostId));              
            dispatch(JobInterviewPreparationDone(isInterview));             
            navigate("/concern", {state:{id:jobPostId,InterviewDone:'Y',CompanyJobTitle:jobCompany+'-'+jobTitle}});
        }      
    } 

    return (
      <div className="common-panel home-page">
        <marquee onmouseover="this.stop();">
         Refer a friend and get rewards <NavLink to="/invite">Refer Now</NavLink>
        </marquee>
        
        <div className="home-container">
                <div className="landing-story-box">
                    <div>
                        <h5>Tell your story and rock the stage!</h5>
                        <p>Finalise your CV and interview in a minute.</p>
                    </div>                  
                    <img src={IMAGE.happy} alt="happy" />
                </div>
                {isJobList ?
                <>
                <div className="btn-text">
                    <h3>My job list</h3>
                    {/* <button onClick={()=>setblankModalShow(true)} className="jobBtn hover-btn"><img src={IMAGE.add_white} alt="add" /> New Job</button>               
                  */}
                    <button onClick={()=>{license==='Expired' ? setExpireModalShow(true) : setblankModalShow(true)}} className="jobBtn hover-btn"><img src={IMAGE.add_white} alt="add" /> New Job</button>               
               
                </div>

<div className="home-tabs">  
    <Tab.Container defaultActiveKey="all">
        <div className="home-nav-tabs">
            <Nav variant="pills">                        
            <Nav.Link eventKey="all"><img src={IMAGE.all_icon} alt="add" /> All job application ({joblist&&joblist.length})</Nav.Link>
            <Nav.Link eventKey="archive"><img src={IMAGE.all_icon} alt="add" /> Archive ({deleteJoblist&&deleteJoblist.length})</Nav.Link>
            </Nav>
        </div>                  

        <Tab.Content>
            <Tab.Pane eventKey="all">
            <div className="table-responsive">
                <table>
                    <thead>
                        <th>Company name</th>
                        <th>Job title</th>
                        <th>Create date</th>
                        <th>Update CV progress</th>
                        <th>Interview preparation</th>
                        <th></th>                        
                    </thead>
                    <tbody>
                        {joblist&&joblist.map((list, index)=>{
                            return (
                            <tr key={index}>
                               
                                {/* <td onClick={()=>ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}> {list?.cname}</td>
                                <td onClick={()=>ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}>{list?.jtitle}</td>
                                <td onClick={()=>ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}>{list?.date}</td>
                                <td onClick={()=>ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}><span className={list?.cvsttus === "In Progress" ? "inprogress" : "completed"}>{list?.cvsttus}</span></td>
                                <td onClick={()=>ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}><span className={list?.interviewstatus === "In Progress" ? "inprogress" : "completed"}>{list?.interviewstatus}</span></td>
                                <td key={index} onClick={()=>ShowDeleteJobpost(list?.rid)}><img src={IMAGE.Delete} alt=''/></td>
                             */}

                                <td onClick={()=>{license==='Expired' ? setExpireModalShow(true) : ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}}> {list?.cname}</td>
                                <td onClick={()=>{license==='Expired' ? setExpireModalShow(true) :ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}}>{list?.jtitle}</td>
                                <td onClick={()=>{license==='Expired' ? setExpireModalShow(true) :ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}}>{list?.date}</td>
                                <td onClick={()=>{license==='Expired' ? setExpireModalShow(true) :ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}}><span className={list?.cvsttus === "In Progress" ? "inprogress" : "completed"}>{list?.cvsttus}</span></td>
                                <td onClick={()=>{license==='Expired' ? setExpireModalShow(true) :ModalHandler(list?.rid, list?.jtitle,list?.cvsttus,list?.interviewstatus,list?.JobPostlink,list?.cname)}}><span className={list?.interviewstatus === "In Progress" ? "inprogress" : "completed"}>{list?.interviewstatus}</span></td>
                                <td key={index} onClick={()=>{license==='Expired' ? setExpireModalShow(true) : ShowDeleteJobpost(list?.rid)}}><img src={IMAGE.Delete} alt=''/></td>
                          
                            </tr>
                            )
                        })}                        
                        
                    </tbody>
                </table>
            </div>
            </Tab.Pane>
            <Tab.Pane eventKey="archive">
            <div className="table-responsive">
                <table>
                    <thead>
                        <th>Company name</th>
                        <th>Job title</th>
                        <th>Create date</th>
                        <th>Update CV progress</th>
                        <th>Interview preparation</th>  
                        <th>Archive Date</th> 
                        <th></th> 
                    </thead>
                    <tbody>
                        {deleteJoblist&&deleteJoblist.map((list, index)=>{
                            return (
                                <tr key={index}>
                                <td> {list?.cname}</td>
                                <td>{list?.jtitle}</td>
                                <td>{list?.date}</td>
                                <td><span className={list?.cvsttus === "In Progress" ? "inprogress" : "completed"}>{list?.cvsttus}</span></td>                                
                                <td>Got Interview</td>
                                <td>{list?.JobPostDeletedDate}</td>
                                <td key={index} onClick={()=>ShowReteriveJobpost(list?.rid)}>
                                    <a href="#" alt="Back to Joblist">Back to Joblist</a> 
                                </td> 
                            </tr>
                            )
                        })} 
                    </tbody>
                </table>
            </div>            
            </Tab.Pane>
        </Tab.Content>
    </Tab.Container>
</div>
</>
:
    <div className='nodata-home'> 
        <img src={IMAGE.startup} alt="start" />
        <p>Get closer to your dream job!</p>
        <button className="themeBtn hover-btn" onClick={()=>setblankModalShow(true)}><i className="fa-solid fa-plus"></i>  Create first job application</button>
    </div>
}     
        </div>
  <Modal show={blankModalShow} size="lg"  aria-labelledby="contained-modal-title-vcenter"    centered  >
      <Modal.Body className='preview-modal p-5'>
        <h2>What you want to do...</h2>
        <div className='row'>
          <div className='col-lg-6 text-center'>
              <button onClick={btnBlankJobListHandle} className="continueBtn">
                <img src={IMAGE.context_card_img} alt="Personalize the CV" />
                <h4>Personalize the CV</h4>
                
                Does your ability match the role? <br></br>

                Let’s  see how to improve it in a blink!
            </button>
          </div>
          <div className='col-lg-6 text-center'>
             <button onClick={btnBlankJobListHandle} className="continueBtn">
                <img src={IMAGE.prepear_icon} alt="Prepare for interview"/>
                <h4>Prepare for interview</h4>
               
                Have no clue how to prepare the interview? <br></br>
                Generate questions and introduction draft.
            </button>                
          </div>
        </div>
         <button className="close_Icon" onClick={()=>setblankModalShow(false)}><img src={IMAGE.close_icon} alt=''/></button>
      </Modal.Body>
  
  </Modal> 

  <Modal
    show={cvUploadDoneModalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body className='preview-modal p-4'>
        <h2>What you want to do...</h2>
        <div className='row'>
          <div className='col-lg-6 text-center'>
          <button onClick={btnCVJobListHandle} className="continueBtn">
            <img src={IMAGE.CompleteCVUpload} alt="Update the CV" />
              {isCVUpload &&
                <img src={IMAGE.crown} className="crown_gif" alt='' />
               }
             <h4>Update the CV</h4>                
                Does your ability match the role?  <br></br>
                Let’s  see how can we improve.
              </button> 

          </div> 
          <div className='col-lg-6 text-center'>
             <button onClick={btnInterviewPrepHandle} className="continueBtn">
                <img src={IMAGE.CompleteInterviewpreparation} alt="Prepare for interview"/>
                {isInterview &&
                  <img src={IMAGE.crown} className="crown_gif" alt='' />
                }
                <h4>Prepare for interview</h4>
               
                Have no clue how to prepare the interview? <br></br>
                Check the potential and introduction draft in following.
            </button>                
          </div>
        </div>
         <button className="close_Icon" onClick={()=>setcvUploadDoneModalShow(false)}><img src={IMAGE.close_icon} alt=""/></button>
      </Modal.Body>  
  </Modal>

    <Modal show={showJobpost} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className='logout-modal text-center p-5'>
            <h4>Are you sure? Do you want to archive the Job Post? </h4>
            <ul>
            <li><button className='btn  btn-primary px-4' onClick={DeleteJobpost}>Yes</button></li>
            <li><button className='btn  btn-outline-danger px-4' onClick={()=>setShowJobpost(false)}>No</button></li>
            </ul>
            <button className="close_Icon" onClick={()=>setShowJobpost(false)}><img src={IMAGE.close_icon} alt='' /></button>
        </Modal.Body>  
    </Modal>

    <Modal show={reteriveJobpost} size="md" aria-labelledby="contained-modal-title-vcenter"  centered>
        <Modal.Body className='logout-modal text-center p-5'>
            <h4>Are you sure? Do you want this job in the Joblist? </h4>
            <ul>
            <li><button className='btn  btn-primary px-4' onClick={ReteriveJobpost}>Yes</button></li>
            <li><button className='btn  btn-outline-danger px-4' onClick={()=>setReteriveJobpost(false)}>No</button></li>
            </ul>
            <button className="close_Icon" onClick={()=>setReteriveJobpost(false)}><img src={IMAGE.close_icon} alt='' /></button>
        </Modal.Body>  
    </Modal>

     <Modal show={expireModalShow} size="lg" aria-labelledby="contained-modal-title-vcenter"  className='subscription-modal'    centered>
      <Modal.Body className=' text-center'>
      <div className='row align-items-center'>
                <div className='col-lg-6'>
                <img src={IMAGE.expire_alert_icon} alt='' className='w-100' />
                </div>
                <div className='col-lg-6 text-center'>
                <img src={IMAGE.pajamas_expire}  alt='' />
                        <h3>Dream Job Awaits!</h3> 
                        <p>You've made great strides. Upgrade now to ensure <br></br> your CV stands out and your interviews are flawless.</p>
                      
                        <NavLink to="/subscription" className="BtnStyle_two">Upgrade Now </NavLink>
                </div>
            </div>
            <button className="close_Icon" onClick={()=>setExpireModalShow(false)}><img src={IMAGE.close_icon} title='close' alt='subscription expired!' /></button> 
      </Modal.Body>  
     </Modal> 
    </div>       
   )
}
export default Home