import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './responsive.css';
import { Provider } from 'react-redux';
import { Store, persistor } from './redux/Store';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
     <PersistGate loading={null} persistor={persistor}> 
        <App />
     </PersistGate>
    </Provider>
);

