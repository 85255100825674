import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./common/Header";
import StepHome from "./component/allstep/StepHome";

import Signup from "./auth/Signup";
import Concern from "./component/concern_gain/Concern";
import { GoogleOAuthProvider } from '@react-oauth/google';
import PrivateRoute from "./common/PrivateRoute";
import PublicRoute from "./common/PublicRoute";
import Home from "./component/home/Home";
import Onboard from "./auth/Onboard";
import { loadStripe } from '@stripe/stripe-js';

import { Elements } from '@stripe/react-stripe-js';
import Subscription from "./payment/Subscription";
import Success from "./payment/Success";
import Cancel from "./payment/Cancel";
import SubscriptionExpired from "./payment/SubscriptionExpired";
import Unsubscribe from "./payment/CancelSubscription";
import ConfirmCancelSubscription from "./payment/ConfirmCancelSubscription";
import UnSubscriptionConfirmation from "./payment/UnSubscriptionConfirmation";
import FinalUnSubscriptionConfirmation from "./payment/FinalUnSubscriptionConfirmation";
import Invoices from "./payment/Invoices";
import Referral from "./component/home/Referral";
function App() {  
  // GET USER EMAIL
  var user_email = localStorage.getItem('ysf_email'); 
  var license= localStorage.getItem('license'); 
  const isProd = process.env.REACT_APP_IS_PROD;
  //let location = useLocation() 
  var stripePromise = loadStripe('pk_test_O5oCxFS4xNIZC8SWSg3788QN00KXBob2XE');
  //Disable Right-Click   
  if(isProd==='Y')
    {    
      document.addEventListener('contextmenu', event => {
        event.preventDefault();
      }); 
     // Load your publishable key from Stripe 
     stripePromise = loadStripe('pk_live_8GBqWmpaKJ5RxYU04s42QZTI005OIZMMq6');  
   }
   const [pageArray] = useState(["onboard", "home", 
    "step-home", "concern", "subscription", "success", "cancel", 
    "expired", "CancelSubscription", "ConfirmCancelSubscription","Unsubscribe", 
    "Cancelconfirm","Invoices","invite"]);
    let pageName=(window.location.pathname).split('/').pop()      
    if(!pageArray.includes(pageName)){
      let JobApp_ReferCoupon=pageName;
      localStorage.setItem('JobApp_ReferId',JobApp_ReferCoupon)       
    }
  return (
    <GoogleOAuthProvider clientId="634646150114-78vjbmfq2i0qbmmr4hfr1spndfru2ub9.apps.googleusercontent.com">
     
    <div className="App">
    <BrowserRouter> 
      {user_email && <Header/>}
       <Routes>      
          <Route element={<PrivateRoute />}>  
                <Route path="/" element={<Signup />} />           
                <Route path="/onboard" element={<Onboard />} />
          </Route>
          <Route element={<PublicRoute />}> 
                <Route path="/home" element={<Home />} />              
                <Route path="/step-home" element={license === 'Expired' ? <Home /> : <StepHome />} />  
                 {/* <Route path="/concern/:id" element={<Concern />} /> */}
                <Route path="/concern" element={license === 'Expired' ? <Home /> : <Concern />} />
                <Route path="/subscription" element={<Elements stripe={stripePromise}><Subscription /></Elements>} />  
                <Route path="/success" element={<Success />} /> 
                <Route path="/cancel" element={<Cancel />} /> 
                <Route path="/expired" element={<SubscriptionExpired />} /> 
                <Route path="/CancelSubscription" element={<Unsubscribe />} /> 
                <Route path="/ConfirmCancelSubscription" element={<ConfirmCancelSubscription />} /> 
                <Route path="/Unsubscribe" element={<UnSubscriptionConfirmation />} /> 
                <Route path="/Cancelconfirm" element={<FinalUnSubscriptionConfirmation />} /> 
                <Route path="*" element={<Home />} />
                <Route path="/Invoices" element={<Invoices />} />
                <Route path="/invite" element={<Referral />} /> 
          </Route>
        </Routes>         
          </BrowserRouter> 
    </div>
    </GoogleOAuthProvider>
  );
}
 
export default App;