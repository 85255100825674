
import React, { useState, useEffect} from "react";
import { useNavigate,useLocation  } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { IMAGE } from "../common/Theme";
import { useGoogleLogin } from '@react-oauth/google';
import ApiConnection from "../common/ApiConnection";
import { Mixpanel } from '../auth/YsfMixPanel';

const Signup = ()=>{
    const [logCount, setLogCount] = useState(0);    
    const [userData, setUserData] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPic, setUserPic] = useState('');      
    let navigate = useNavigate();   
    
    var settings = {
     dots: false,
     infinite: true,
     speed: 3000,
     slidesToShow: 1,
     slidesToScroll: 1,
     arrows:false,
     autoplay:true,
     fade:true,
     autoplaySpeed:3000
    }; 
   
    const LoginHandle=useGoogleLogin({

        onSuccess: codeResponse => {
            console.log(codeResponse);
            if(codeResponse.access_token)
            {
                let url_='https://www.googleapis.com/oauth2/v3/userinfo?access_token='+codeResponse.access_token;
                fetch(url_)
                .then((res) => res.json())
                .then ((responseData)  => {               
                    if(responseData.email_verified)
                    {
                            setLogCount(1);
                            setUserData(responseData);  
                            setUserEmail(responseData.email);   
                            setUserName(responseData.name); 
                            setUserPic(responseData.picture);

                            Mixpanel.identify(responseData.email);                     
                            Mixpanel.people.set({
                                $first_name: responseData.name,
                                $email: responseData.email
                            });
                            Mixpanel.track('YsfJobApp:Successful login');
                        
                            const UserSignup =  async ()=>{
                                try{

                                    let body = {
                                        UserName:responseData.name,
                                        UserEmail:responseData.email 
                                    }
                                    const response = await ApiConnection.post("/JobbsprangetUser/RegisterUser", body)
                                    console.log(response);
                                    if (response !== "" || response !== "TimeOut Error: Try Again") {
                                        if(response.data?.isOnboardingSuccess)
                                        {
                                                localStorage.setItem('ysf_name', responseData.name);
                                                localStorage.setItem('ysf_email', responseData.email);                                                                                                                              
                                                localStorage.setItem('DaysLeft', response.data.licenseLeftdays);
                                                localStorage.setItem('license', response.data.license);
                                                localStorage.setItem('subscriptionId', response.data.subscriptionId);                                            
                                                localStorage.setItem('JobApp_UserId',response.data.userId);
                                                localStorage.setItem('JobApp_SubscriptionType', response.data.subscriptionType);                                                                                        
                                                let user_name=responseData.given_name; 
                                                if(user_name)
                                                    {
                                                        user_name= user_name.slice(0,1).toUpperCase() + user_name.slice(1, user_name.length);
                                                        localStorage.setItem('fname', user_name);
                                                    }
                                                    else
                                                    {
                                                        localStorage.setItem('fname', responseData.given_name);
                                                    }                                                                                      
                                                window.location.reload();                                           
                                        } 
                                        else{
                                            navigate("/onboard", {state:{user_name:responseData.name, user_email:responseData.email,first_name:responseData.given_name,license:response.data.license,DaysLeft:response.data.licenseLeftdays,subscriptionId:response.data.subscriptionId}});
                                        }
                                    }
                                } 
                                catch(e){
                                    console.log("Error! in Sign Up!");
                                }                      
                            }
                            UserSignup();             
                    }             
                });
            }
        },
        onError: codeResponse => {
            console.log(codeResponse);
            console.log("Fail")
        }
    });   

    return (
     <div className="login-page p-0">
             <div className="signup-container">
                 <div className='row no-gutters h-100'>
                     <div className='col-lg-8 col-sm-7'>
                         <div className='signup-container-lft h-100'>
                         <Slider {...settings} className="h-100">
                             <div className='slider-animate'>
                                 <h3>Make your CV stand <br></br> out with <span>AI analysis</span></h3>
                                 <img src={IMAGE.slider_one} alt=''  />
                             </div>
                             <div className='slider-animate'>
                                 <h3>Elevate your interview <br></br> preparation with AI <br></br> and <span>Neuroscience</span></h3>
                                 <img src={IMAGE.slider_two} alt=''     />
                             </div>
                             </Slider>
                         </div>
                     </div>
                     <div className='col-lg-4 col-sm-5'>                       
                         <div className='signup-container-rht'>
                             <div className='signup-container-rht-inner'>
                                  <h3>Create an Account</h3>
                                  <button onClick={LoginHandle} className="hover-btn"> 
                                     <img src={IMAGE.google_icon} alt=''    />
                                     Log in with Google
                                  </button> 
                             </div>
                             <h6>By continuing, you agree to our <a href="https://www.yourspeechfactory.com/eula" target="_blank" rel='noreferrer'>Terms of Service</a> and <a href="https://www.yourspeechfactory.com/privacy-policy" target="_blank" rel='noreferrer'>Privacy Policy.</a></h6>
                     
                         </div>
                     </div>
                 </div>
             </div> 
     </div>
   )
}
export default Signup