import React, { useEffect, useState } from "react"
import { IMAGE } from '../common/Theme';
import ApiConnection from '../common/ApiConnection';

var user_email = localStorage.getItem('ysf_email');
//console.log("#SubscriptionId#"+SubscriptionId);
const Invoices = () => { 
  const [invoiceList, setInvoiceList] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  useEffect(()=>{  
   
      const getInvoiceList = async ()=>{
        try{
          let getInvoiceObj = { 
            'UserEmail':user_email
           }

           //console.log("getInvoiceObj#"+getInvoiceObj);  
          const response = await ApiConnection.post("/Invoice/JobappInvoicedownload", getInvoiceObj);              
          var InvoiceArray = [];
          setInvoiceList(InvoiceArray);          
          if(response.data?.isSuccess && response.data?.invoice){
            var invoiceList=response.data?.invoice;
            setCustomerId(response.data?.customerId);
            setSubscriptionId(response.data?.subscriptionId);

            invoiceList?.forEach(invoice => {
              if(invoice.status==='paid'){ 
                const formattedStartDate = formatDate(invoice.subscriptionStartDate);
                const formattedEndDate = formatDate(invoice.subscriptionEndDate);
                const invoicePeriod = `${formattedStartDate}-${formattedEndDate}`;             
                  // Check if an invoice with the same period already exists in the array
                const periodExists = InvoiceArray.some(inv => inv.invoicePeriod === invoicePeriod);
                if (!periodExists) {
                    InvoiceArray.push(
                    {
                    inv:invoice.invoicePdfUrl,
                    invDesc:invoice.invoiceDesc,
                    status:invoice.status, 
                    createdDate:invoice.createdDate,
                    invoicePeriod:formatDate(invoice.subscriptionStartDate)+'-'+formatDate(invoice.subscriptionEndDate)
                    });
                }
              }
            });           
            setInvoiceList(InvoiceArray);
          }
          else{
            console.log("No");
          }
        }
        catch(error){
          console.log("Error in Invoice List!!");
        }
      }        
      getInvoiceList();   
  },[]);

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    // Format the date using toLocaleDateString
    return date.toLocaleDateString('en-US', {
      day: '2-digit',  // 'numeric' for single digit days
      month: 'long',   // Use 'short' for abbreviated month (e.g., "Sep")
      year: 'numeric'
    });
  }
  


  return (
    <div className="common-panel home-page">
      <div className="home-container invoice-sec">
      <div className="invoice-title">
      <img src={IMAGE.billing} alt="bill" />
          <div>
              <h3>Invoices</h3> 
              <h4>Subscription Id: {subscriptionId}</h4>
              <h4>Customer ID: {customerId}</h4> 
          </div>
       </div>

      <div className="table-responsive home-tabs">
                <table>
                    <thead>
                       <th>S.No.</th>                        
                       <th>Invoice Duration</th>
                       <th>Status</th>                      
                       <th></th>                                         
                    </thead>
                    <tbody>
                        {invoiceList&&invoiceList.map((list, index)=>{
                            return (
                                <tr key={index}>  
                                <td> {index+1}</td> 
                                <td> {list?.invoicePeriod}</td>                               
                                <td> {list?.status}</td>                                 
                                <td key={index}>
                                    <a className="btn btn-outline-danger" href={list?.inv} alt="Download invoice">Download invoice</a> 
                                </td> 
                            </tr>
                            )
                        })} 
                    </tbody>
                </table>
          </div>

      </div>
    </div>    
  )
}
export default Invoices;