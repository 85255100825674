import React,{useState} from 'react';
import ApiConnection from '../common/ApiConnection';
import { IMAGE } from '../common/Theme';
import { NavLink, useNavigate } from 'react-router-dom';

const FinalunsubscriptionConfirmation = () => {
  let navigate = useNavigate();
  const GoToHome = (step) => { 
    localStorage.removeItem('subscriptionId');       
    navigate("/home");   
    window.location.reload();  
  };
  return (
    <div className="common-panel home-page success-box" >
    <div className="home-container">
        <div className='subscription-container '>
            <div className='row align-items-center'>               
                <div className='col-lg-12 text-center'>                
                        <h3>You’re now unsubscribed!</h3>                       
                        <p style={{margin:'0 0 17px 0'}}>We wish you the best in your career journey. </p>                      
                      <ul className='success-box_btn'>
                        <li>
                            <button className="BtnStyle_two"                        
                            onClick={() => GoToHome()}
                            >Close</button>             
                        </li>                        
                      </ul>
                </div>
            </div> 
        </div>
    </div>
   </div>
  )
}

export default FinalunsubscriptionConfirmation