import React from 'react'
import { useSelector } from 'react-redux'
import JobPost from './JobPost'
import JobProfile from './JobProfile'

const StepHome = () => {
  const fetchReducer = useSelector((state) => state.dataflowreducer)
  return (
    <div className="main-layout">
      <div className='container-fluid'>
      {fetchReducer?.stepcount ===1 &&
        <JobPost/>
      }
      {fetchReducer?.stepcount ===2 &&
        <JobProfile/>
       }           
      </div>
    </div>
  )
}

export default StepHome