import React from 'react'

const Nodata = ({image, text}) => {
  return (
    <div className='no-data'>
        <img src={image} alt=''/>
        <h5>Here is where the magic happens...</h5>
        <p>{text}</p>
    </div>
  )
}

export default Nodata