import React, { useEffect, useState, useRef } from "react"
import { IMAGE } from '../../common/Theme'
import Modal from 'react-bootstrap/Modal';
import ApiConnection from "../../common/ApiConnection";
import copy from "copy-to-clipboard";

            
const Referral = () => {
  const [referralLink, setReferralLink] = useState("");
  const [refermodal, setrefermodal] = useState(false);
  const [referUserName, setReferUserName] = useState("");
  const [referUserEmail, setReferUserEmail] = useState("");
  const [referlist, setReferlist] = useState([]); 
  const [sendMsg, setSendMsg] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [selectedValue,setSelectedValue,] = useState("option1");  
  const [WhatsAppLink, setwhatsAppLink] = useState("");
  const [earnMonths, setEarnMonths] = useState(0);
  
  const isProd = process.env.REACT_APP_IS_PROD; 
  var user_email = localStorage.getItem('ysf_email');
  var jobappUserId = localStorage.getItem('JobApp_UserId');
  
  const refrralNameInput = useRef(null);
  const refrralEmailInput = useRef(null);
  const popupWindowRef = useRef(null);
  const popupCheckIntervalRef = useRef(null);

  useEffect(()=>{        
    if(isProd==='Y') 
      setReferralLink("https://app.jobsway.ai/"+jobappUserId);  
    else{    
      setReferralLink("https://jobswayqa.azurewebsites.net/"+jobappUserId);
      }    
    getReferList();   
  },[isProd,user_email,jobappUserId]);
  
  const getReferList = async ()=>{
    try {
        let getReferListObj = {'UserEmail':user_email}        
        const response = await ApiConnection.post("/JobbsprangetEmail/GetReferralEmail", getReferListObj);
        if(response.data?.isSuccess){                               
              var ReferArray = [];  
              let completedCount=0;
              var referList=response.data?.referUserList;
              referList?.forEach(element => {  
                if(element.referStatus==="Completed"){                    
                  completedCount+=1;
                 }
                                  
                ReferArray.push({                    
                  ReferUserName:element.referUserName,
                  ReferUserEmail:element.referUserEmail,                   
                  ReferStatus:element.referStatus,
                  ReferLogDate:element.referLogDate                  
                });
              });
              setReferlist(ReferArray); 
              setEarnMonths(completedCount);             
          }
        }
        catch(error){
              console.log("Error in loading Refer list!!!");
        }
  }

  const btnReferHnd= async () => { 
    if(isProd==='Y') 
      setReferralLink("https://app.jobsway.ai/"+jobappUserId);  
    else
      setReferralLink("https://jobswayqa.azurewebsites.net/"+jobappUserId);

    if(!referUserName.trim()){        
        setSendMsg("Please enter Referral Name!!");
        setIsSend(false);  
        refrralNameInput.current.focus();             
        return;
      }   
      if(!referUserEmail.trim()){
        setSendMsg("Please enter Referral Email!!");
        setIsSend(false); 
        refrralEmailInput.current.focus();        
        return;
      }
      let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      if (!emailRegex.test(referUserEmail.trim())) {
        setSendMsg("Please enter valid Email!!");
        setIsSend(false); 
        refrralEmailInput.current.focus(); 
        return;
      }
      if((referUserEmail).trim().toLowerCase().includes(user_email.toLowerCase())){
        setSendMsg("You can not refer self. Please enter friends Email!!");
        setIsSend(false); 
        refrralEmailInput.current.focus(); 
        return;
      }
      if(referlist.some(refer => (refer.ReferUserEmail).toLowerCase() === referUserEmail.trim().toLowerCase())){
        setSendMsg("You already referred to.");
        setIsSend(false); 
        refrralEmailInput.current.focus(); 
        return;
      } 
     
      let refName=referUserName.slice(0,1).toUpperCase() + referUserName.slice(1, referUserName.length)
      console.log("refName"+refName);
      setReferUserName(refName);  
      let refrralUserObj = {          
        UserEmail:user_email,
        ReferUserName:referUserName.trim(),
        ReferUserEmail:referUserEmail.trim(),
        ReferLink:referralLink      
      }   
      var response= await ApiConnection.post("/JobbsprangetEmail/ReferralEmail", refrralUserObj);     
      if(response.data?.isSuccess){         
        setSendMsg(response.data.emailSendMessage);
        setIsSend(true);  
        getReferList();       
      }
      else{
        setSendMsg(response.data.emailSendMessage);
        setIsSend(false);
      }     
  }

  const referralName =(value)=>{    
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setReferUserName(capitalizedValue); 
  }
  const referralEmail =(value)=>{
    setReferUserEmail(value);
  }
  const copyReferLink = () => {       
    copy(referralLink); 
    setIsCopy(true);
  };
  const ReferralModel =()=>{ 
    if (refrralNameInput.current) {
      refrralNameInput.current?.focus(); 
    }  
    setSendMsg("");
    setIsSend(true);  
    setrefermodal(false);
    setReferUserName("");
    setReferUserEmail("");  
    setIsCopy(false); 
    setSelectedValue("option1");
    getReferList();   
  }
  const OpenReferralModel = () => {       
    setrefermodal(true);
    setSelectedValue("option1");
  };

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    if(value==='option2'){
      const message = `Hi! I use Jobsway.ai for CV feedback and cover letter assistance. It’s super easy and packed with great features! Try it out with a free one-month subscription link! `+referralLink +' or one-month free coupon code: '+jobappUserId;     
      const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;              
      setwhatsAppLink(whatsappLink);      
      openWhatsAppWindow(WhatsAppLink);
    }    
  };
 
  const openWhatsAppWindow = (whatsappLink) => {    
    const windowName = "Your Speech Factory"; // Name of the new window
    const width = 680;
    const height = 550;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    const windowFeatures = `width=${width},height=${height},left=${left},top=${top}`;   
    const newWindow = window.open(whatsappLink, windowName, windowFeatures);
  
     // Check if the window is successfully opened and then set the title
   if (newWindow) {
      newWindow.onload = () => {
        newWindow.document.title = windowName; // Set the title
      };
    }
    
    popupWindowRef.current=newWindow;
    // Start checking if the window is closed
    popupCheckIntervalRef.current = setInterval(() => {
     if (popupWindowRef.current && popupWindowRef.current.closed) {
          clearInterval(popupCheckIntervalRef.current);
          setSelectedValue("option1");
      }
      }, 500); // Check every 500ms   
  };

  useEffect(() => {
    // Cleanup interval when component unmounts
    return () => {
      if (popupCheckIntervalRef.current) {
        clearInterval(popupCheckIntervalRef.current);
      }
    };
  }, []);

  return (
    <div className='common-panel home-page'>
      <div className='wallet-container'>
        <div className="refer-container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h3>Invite and get <br></br> a month free!</h3>
              <h5>Earn 1 month extra subscription</h5>
              <div className='refer-url'>
                <input type='text' className='form-control' value={referralLink} />
                <div className="refer-url-right">
                  <button onClick={copyReferLink}>Copy</button>
                  <button onClick={OpenReferralModel}>Refer Now</button>
                </div>
              </div>
              {isCopy &&
                <span style={{ color: "green" }}>Text copied to clipboard!</span>}
            </div>
            <div className="col-lg-5">
              <img src={IMAGE.refer_img} alt="" className="w-100" />

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="get-box">
              <h3>They Get</h3>
              <div className="get-box_list">
                <img src={IMAGE.check} alt="Check" />
                <h5>A <b> free month of premium subscription </b> with every feature unlocked when they sign up through your link!</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="get-box">
              <h3>You Get</h3>
              <div className="get-box_list">
                <img src={IMAGE.check} alt="Check" />
                <h5>The sky’s the limit! Get
                <b> one month of premium subscription free</b>(€11.60 value) with full access to all features for each friend who signs up using your link. The more you invite, the more free months you earn!</h5>
              
              </div>  
              {earnMonths>0 &&
                <div className="get-box_list">
                        <img src={IMAGE.check} alt="Check" />                    
                        <h5>You have earn:<b> {earnMonths} {earnMonths>1?"months":"month"}</b></h5>                     
                </div> 
              }
            </div>
          </div>
        </div>
        <div className="table-responsive home-tabs mt-4">
          <h3>Invited friends List</h3>
          <table>
            <thead>
              <th>Refer User Name</th>
              <th>Refer User Email</th>
              <th>Status</th>
              <th>Date</th>
            </thead>
            <tbody>
              {referlist && referlist.map((list, index) => {
                return (
                  <tr key={index}>
                    <td> {list?.ReferUserName}</td>
                    <td> {list?.ReferUserEmail}</td>
                    <td> {list?.ReferStatus}</td>
                    <td> {list?.ReferLogDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

      </div>
      <Modal show={refermodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className='logout-modal text-center p-5'>
          <h4>Refer a friend </h4>
          <hr />
          <div className='form-group mb-2 mt-2'>
            <input type="radio" id="option1" value="option1"
              checked={selectedValue === "option1"} onChange={() => handleRadioChange("option1")} />&nbsp;&nbsp;
            <label htmlFor="option1">Email</label>&nbsp;&nbsp;&nbsp;&nbsp;

            <input type="radio" id="option2" value="option2" checked={selectedValue === "option2"} onChange={() => handleRadioChange("option2")} />&nbsp;&nbsp;
            <label htmlFor="option2">WhatsApp</label>
          </div>
          {selectedValue === "option1" &&
            <><div className='form-group mb-3 mt-4'>
              <input type='text' ref={refrralNameInput} autoFocus className='form-control' placeholder="Name" value={referUserName} onChange={(e) => referralName(e.target.value)} />
            </div><div className='form-group mb-5'>
                <input type='text' ref={refrralEmailInput} className='form-control' placeholder="Email" value={referUserEmail} onChange={(e) => referralEmail(e.target.value)} />
              </div><button className='btn  btn-primary px-4' onClick={btnReferHnd}>Send</button><div className='form-group mb-6'>
                <span style={{ color: isSend === true ? "green" : "red" }}>{sendMsg} </span>
              </div></>}

          <button className="close_Icon" onClick={ReferralModel}><img src={IMAGE.close_icon} alt='' /></button>

        </Modal.Body>
      </Modal>
    </div>
   )
}
export default Referral