import React, { useRef, useEffect,useState } from 'react'
import { NavLink,useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { Mixpanel } from '../auth/YsfMixPanel';
import { IMAGE } from '../common/Theme'
import ApiConnection from './ApiConnection';

const Header = () => {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [DaysLeft, setDaysLeft] = useState(null);
  const [isSubscribe, setIsSubscribe] = useState(0); 
  const [referCoupon, SetReferCoupon] = useState("");

  const [pageArray] = useState(["onboard", "home", 
    "step-home", "concern", "subscription", "success", "cancel", 
    "expired", "CancelSubscription", "ConfirmCancelSubscription","Unsubscribe", 
    "Cancelconfirm","Invoices","invite"]);

  const dropdownRef = useRef(null); 
  const fetchReducer = useSelector((state) => state.dataflowreducer)
  var user_name = localStorage.getItem('fname');
  var user_email = localStorage.getItem('ysf_email');
  if(user_name){ user_name =user_name.slice(0,1).toUpperCase() + user_name.slice(1, user_name.length) }
  var daysLeft= localStorage.getItem('DaysLeft');
  var license= localStorage.getItem('license'); 
  var subscriptionId =localStorage.getItem('subscriptionId'); 
  const [language, setLanguage] = useState({img:IMAGE.UK, lan:'English'});
  var jobAppLang = localStorage.getItem('jobAppLang');
  let JobApp_ReferId=localStorage.getItem('JobApp_ReferId');   
  const location = useLocation()
  
  useEffect(()=>{
    GetUserdata();
  },[])

  useEffect(()=>{    
    switch(jobAppLang){
      case "English":
        handleLanguageChange(IMAGE.UK,  'English');
        break;
        case "Italian":
          handleLanguageChange(IMAGE.Italy, 'Italian')
          break;
          case "Swedish":
            handleLanguageChange(IMAGE.Sweden, 'Swedish')
            break;
          default:             
    }     
  },[jobAppLang]); 

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {    
    if(!JobApp_ReferId){
      let pageName=(window.location.pathname).split('/').pop()      
      if(!pageArray.includes(pageName)){
        let JobApp_ReferCoupon=(location.pathname).slice(1);
        localStorage.setItem('JobApp_ReferId',JobApp_ReferCoupon)       
      }   
    }
    else{
      SetReferCoupon("Upgrade Now with free a month coupon ("+JobApp_ReferId.toUpperCase()+")");
    }       
  }, []);
  
  const LogoutHandle = () => {
      try
      {
        localStorage.removeItem("email_verified");
        localStorage.removeItem('ysf_email');
        localStorage.removeItem('ysf_user');
        localStorage.removeItem('ysf_name');
        localStorage.removeItem('fname');
        localStorage.removeItem('DaysLeft');
        localStorage.removeItem('license'); 
        localStorage.removeItem('JobApp_SubscriptionType'); 
        localStorage.removeItem('JobApp_UserId'); 
        localStorage.removeItem('JobApp_ReferId'); 
        localStorage.removeItem('JobappUserId');     
        Mixpanel.track('YsfJobApp:Logout');
        window.location.reload();      
      }
      catch(error){
        console.log("Error");
      }
  }
  
  const handleLanguageChange = (img, lan) => {
    const selectedLanguage = { img, lan };
    setLanguage(selectedLanguage);
    localStorage.setItem('jobAppLang', selectedLanguage.lan);      
    setIsOpen(false);
  };
  const toggleDropdown = () => { 
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  
  const GetUserdata = async ()=>{
    let getUserDataObj = {
      UserName:user_name,
      UserEmail:user_email 
    }
    const response = await ApiConnection.post("/JobbsprangetUser/RegisterUser", getUserDataObj);    
    if (response.data.isSuccess) {
        //console.log("isSubscribe#"+response.data.isSubscribe);
        daysLeft=response.data.licenseLeftdays;
        license=response.data.license;
        subscriptionId=response.data.subscriptionId;
        setIsSubscribe(response.data.isSubscribe);
        setDaysLeft(response.data.licenseLeftdays);
        localStorage.setItem('DaysLeft', response.data.licenseLeftdays);
        localStorage.setItem('license', response.data.license);
        localStorage.setItem('subscriptionId', response.data.subscriptionId);
        localStorage.setItem('JobApp_UserId',response.data.userId);
        localStorage.setItem('JobApp_SubscriptionType', response.data.subscriptionType);           }
  }

  return (
    <div className='header'>
    <div className='container-fluid'>
    <div className='row align-items-center'> 

    {license==='Expired'? 
        (
          <div className='col-lg-2 col-5'>
                  <NavLink to="/subscription">
                    <img src={IMAGE.logo_svg} alt='logo' className='logo' />
                  </NavLink>                  
          </div>     
        )
        :
        ( 
            <div className='col-lg-2 col-5'>
            <NavLink to="/">
            <img src={IMAGE.logo_svg} alt='logo'  className='logo' />
            </NavLink>
           </div>
        )
      }
      
      <div className='col-lg-10 col-7'>
          <div className='header-right'> 
           <div id="userInfo" className='header-right'>             
              <label>{fetchReducer?.title}</label>
              <Dropdown className='header-dropdown'>
                  <Dropdown.Toggle  id="dropdown-basic">                    
                      <span>{(user_name)?.substring(0,2)}</span>
                  </Dropdown.Toggle> 
                  <Dropdown.Menu>
                      <div className='dopdown-top'>                       
                          <h5>{user_email}</h5>
                          <span>{(user_name)?.substring(0,2)}</span>
                      </div>
                      <ul>
                        <li><NavLink to="/subscription">Subscription</NavLink></li> 
                        {subscriptionId && 
                          <li><NavLink to="/CancelSubscription">Cancel subscription </NavLink></li> 
                        }                         
                        <li><NavLink to="/Invoices">Invoice</NavLink></li> 
                        <li><a href='https://www.yourspeechfactory.com/support' alt='Support' rel="noreferrer"  target="_blank">Support</a></li>
                        <li><a href="https://www.yourspeechfactory.com/about" alt='about' rel="noreferrer" target="_blank">About(1.0.1.6)</a></li>
                        <li><button className='signOutBtn' onClick={()=>setShow(true)}>Sign Out</button></li>                        
                      </ul>
                  </Dropdown.Menu>
                </Dropdown>             
          </div>
          <div className='languages-container'>
          <div className='header-dropdown language-dropdown' ref={dropdownRef}>
          <button onClick={toggleDropdown}  className="dropdown-toggle">
          <span><img src={language.img} alt={language.lan} title={language.lan} /></span>
          </button>
            {isOpen && (
            <div className='dropdown-menu'> 
                <ul>
                    <li>
                    <h5 id="Lang_H5ChangeLang">CHANGE OUTPUT LANGUAGE</h5>
                    </li>
                    <li className={language.lan === 'English'? 'active' : ''} onClick={()=>handleLanguageChange(IMAGE.UK,  'English')}>
                      <img src={IMAGE.UK} title="English" alt='English'/> English
                    </li>
                    <li className={language.lan === 'Italian'? 'active' : ''} onClick={()=>handleLanguageChange(IMAGE.Italy,  'Italian')}>
                      <img src={IMAGE.Italy} title="Italian" alt='Italian' /> Italian
                    </li>
                    <li className={language.lan === 'Swedish'? 'active' : ''} onClick={()=>handleLanguageChange(IMAGE.Sweden, 'Swedish')}>
                      <img src={IMAGE.Sweden} title="Swedish" alt='Swedish' /> Swedish
                    </li>
                </ul>
                <div class="l-note">
                    <h5 id="Lang_H5Note">PLEASE NOTE</h5>
                    <p id="Lang_paraChanges">Changing the output language won't change the interface language.</p>
                    <div className='language-information'>
                        <h4>Steps to change the output language:</h4>
                        <ul>
                          <li>Select your desired language.</li>
                          <li>Click the "Generate" button to apply the change.</li>
                        </ul>
                    </div>
                    <div className='language-information'>
                        <h4>Troubleshooting:</h4>
                        <ul>                         
                          <li>Results looking strange? Simply click "Generate" to fix it up.</li>
                        </ul>
                    </div>
                </div>
            </div>
            )
          }
          </div>
          </div>
        </div>
      </div>
      
      {(isSubscribe===0 && parseInt(daysLeft) <=7) && 
        <div className='col-lg-12 col-12'> 
             <h5 className='subscriptionText text-center'>{parseInt(daysLeft) === 0 ? 'Your subscription plan expires today' : parseInt(daysLeft) < 0 ? 'Your subscription plan expired' : `Your subscription plan expires in ${parseInt(daysLeft)} days`}. 
             {!JobApp_ReferId ?           
             <NavLink to="/subscription"> Upgrade Now </NavLink>
             : 
             <NavLink to={{ pathname: '/subscription', state: { JobApp_ReferId } }}> {referCoupon}  </NavLink>
             }
             </h5>            
        </div> 
      }
</div>
</div>
    <Modal show={show} size="md" aria-labelledby="contained-modal-title-vcenter"      centered>
      <Modal.Body className='logout-modal text-center p-5'>
        <h4>Are you sure to logout? </h4>
        <ul>
          <li><button className='btn  btn-primary px-4' onClick={LogoutHandle}>Yes</button></li>
          <li><button className='btn  btn-outline-danger px-4' onClick={()=>setShow(false)}>No</button></li>
        </ul>
         <button className="close_Icon" onClick={()=>setShow(false)}><img src={IMAGE.close_icon} alt='' /></button>
      </Modal.Body>  
    </Modal>
</div>

 )
} 
export default Header