import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  stepcount: 1,
  stepcountedit: 1,
  url:"",
  title:"",
  jobobj:"",
  jobpostId:"",
  goToConceren:"",
  jobInterviewPreparationDone:""
};

export const dataflowreducer = createSlice({
  name: "dataflowreducer",
  initialState,
  reducers: {
    StepAction(state, action) {
      state.stepcount = action.payload;
    },
   
    StepEditAction(state, action) {
      state.stepcountedit = action.payload;
    },
    JobpostUrlAction(state, action) {
      state.url = action.payload;
    },
    JobTitleAction(state, action) {
      state.title = action.payload;
    },
    JobPostAction(state, action) {
      state.jobobj = action.payload;
    },
    JobPostIdAction(state, action) {
      state.jobpostId = action.payload;
    },
    GoToConcerenAction(state, action) {
      state.goToConceren = action.payload;
    },
    JobInterviewPreparationDone(state, action) {
      state.jobInterviewPreparationDone = action.payload;
    },
    // reset the state to its initial value
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  StepAction,
  StepEditAction,
  JobpostUrlAction,
  JobTitleAction,
  JobPostAction,
  JobPostIdAction,
  GoToConcerenAction,
  JobInterviewPreparationDone,
  resetState,
} = dataflowreducer.actions;

export default dataflowreducer.reducer;
