import React,{useState,useRef,useEffect } from 'react';
import { useNavigate,NavLink} from 'react-router-dom';

import {useStripe} from '@stripe/react-stripe-js';
import ApiConnection from '../common/ApiConnection';
import { Mixpanel } from '../auth/YsfMixPanel';
import { IMAGE } from '../common/Theme'

const Subscription = () => {  
  const stripeAll = useStripe();
  const [paymentType, setPaymentType] = useState(''); 
  const [subType, setSubType] = useState(''); 
  
  const [isWeeklyCoupon, setIsWeeklyCoupon] = useState(false); 
  const [weeklyCoupon, setWeeklyCoupon] = useState('');     
  const [weeklyCouponValidation, setWeeklyCouponValidation] = useState('');  
  
  const [monthlyCoupon, setMonthlyCoupon] = useState('');    
  const [monthlyCouponValidation, setMonthlyCouponValidation] = useState('');   
  const [isMonthlyCoupon, setIsMonthlyCoupon] = useState(false); 

  const [isQuaterlyCoupon, setIsQuaterlyCoupon] = useState(false);   
  const [quaterlyCoupon, setQuaterlyCoupon] = useState('');       
  const [quaterlyCouponValidation, setQuaterlyCouponValidation] = useState('');   
    
  const weeklyCouponRef = useRef(null);  
  const monthlyCouponRef = useRef(null);
  const quaterlyCouponRef = useRef(null); 
  const btnMonthlyCouponRef = useRef(null);

  var user_email = localStorage.getItem('ysf_email');  
  var subscriptionType =localStorage.getItem('JobApp_SubscriptionType');
  const isProd = process.env.REACT_APP_IS_PROD;  
  var navigate=useNavigate(); 
  let JobApp_ReferId=localStorage.getItem('JobApp_ReferId');
  console.log("subscriptionType¤"+subscriptionType); 
  useEffect(() => {   
    console.log("JobApp_ReferId!"+JobApp_ReferId); 
    if(JobApp_ReferId){
      setPaymentType("2");
      setMonthlyCoupon(JobApp_ReferId.toUpperCase());
      SetCouponVal(JobApp_ReferId.toUpperCase());  
      if (btnMonthlyCouponRef.current) {
        btnMonthlyCouponRef.current.click();
      }           
    }         
  }, []);
  

  const btnWeeklyPaymentHandle= async () => { 
    if (!stripeAll) {     
      return;
    }
    if(isProd==='N') 
      PaymentHandle(1,"price_1PXj3aJEaxc25mG4unU8s0v6",weeklyCoupon);    
  else     
      PaymentHandle(1,"price_1PXllqJEaxc25mG4VcSsRJMb",weeklyCoupon);
  }

  const btnMonthlyPaymentHandle= async () => { 
    if (!stripeAll) {    
      return;
    }

    if(isProd==='N') 
      PaymentHandle(2,"price_1PQPALJEaxc25mG4LKxN6Fxa",monthlyCoupon);    
   else     
      PaymentHandle(2,"price_1PXjoaJEaxc25mG43Y1T9b9T",monthlyCoupon);          
  }

  const btnQuaterlyPaymentHandle= async (event) => { 
    if (!stripeAll) {
      console.log("stripe not enabled!!");
      return;
    }
    
    if(isProd==='N') 
      PaymentHandle(3,"price_1PQPQyJEaxc25mG44fFoeIc2",quaterlyCoupon);     
   else     
      PaymentHandle(3,"price_1PXjpDJEaxc25mG4zfadyAuz",quaterlyCoupon);
  }

  const PaymentHandle= async (_paymentType,_priceId,_coupon) => {
    console.log("userEmail"+user_email);
    console.log("_priceId"+_priceId);    
    console.log("_paymentType"+_paymentType); 
    console.log("_coupon#"+_coupon); 
    try
    {
      if(parseInt(subscriptionType)>0){ 
        setSubType("You Aleady have active subscription!!");
        return; 
      }
      setSubType("");
      if(_coupon){      
        let getfreeTrialCouponObj = { 'Couponcode':_coupon,'UserEmail':user_email}    
        const response = await ApiConnection.post("/JobbsprangetCoupon/FreeTrialCouponValidation", getfreeTrialCouponObj);
        if(response.data?.isSuccess){  
          Mixpanel.track(`YsfJobApp:FreeTrial Subscription for:${_paymentType === 1 ? '1 Week' : _paymentType === 2 ? '4 Weeks' : '12 Weeks'}`);            
          navigate("/home"); 
          window.location.reload(); 
          return;  
        }
      }

      if(_coupon==="")_coupon="";     
      switch(_paymentType){
        case 1:
          if(!isWeeklyCoupon)_coupon="";
        break;
        case 2:
          if(!isMonthlyCoupon)_coupon="";
        break;
        case 3:
          if(!isQuaterlyCoupon)_coupon="";
        break;
        default:
      }         

      let paymentRequest = {
        'PriceId': _priceId, 
        'Quantity': 1,
        'CouponId': _coupon,
        'PaymentType': _paymentType,
        'UserEmail':user_email 
       } 
      const { data: { id,email } }  = await ApiConnection.post("/api/JobbsprangetPayment/create-checkout-session", paymentRequest);           
      const { error } = await stripeAll.redirectToCheckout({
        sessionId: id,
      });
      if (error) {
        console.error('Stripe Checkout error:', error);
      }
      else{
        //Mixpanel.track('YsfJobApp:PayNow:'+_paymentType+"#"+email+"#"+_coupon);
        Mixpanel.track(`YsfJobApp:PayNow:${_paymentType === 1 ? '1 Week' : _paymentType === 2 ? '4 Weeks' : '12 Weeks'} '#' ${email} '#'${_coupon}`);     
      }
    }
    catch(ex){
       console.log('Error in subscription!!');
    }   
  }

  const SubscribeType = (subType) => {  
    setPaymentType(subType); 
  }

  const ApplyCoupon= async () => {    
    let coupon;
    try
     {        
      switch(paymentType){       
        case "1": 
          coupon=weeklyCoupon;          
          if (weeklyCoupon.length===0) {
             coupon="";
             weeklyCouponRef.current.focus(); 
             setWeeklyCouponValidation("Please enter coupon code!!");
            return;
          }                                      
          break;
        case "2":  
           coupon=monthlyCoupon;             
          if (monthlyCoupon.length===0) {
              coupon="";
              monthlyCouponRef.current.focus(); 
              setMonthlyCouponValidation("Please enter coupon code!!");
              return;
          }       
          break;
        case "3":  
          coupon=quaterlyCoupon;       
          if (quaterlyCoupon.length===0) {
            coupon="";
            quaterlyCouponRef.current.focus();
            setQuaterlyCouponValidation("Please enter coupon code!!");
            return;
          }
          break;
        default: 
        coupon=JobApp_ReferId;
        setPaymentType("2");
        break;         
      } 
      let validateCoupon = 
      {
        'Couponcode':coupon.toUpperCase(),
        'CouponPlan':(paymentType.length===0? "2":paymentType),
        'UserEmail':user_email
      }
      const response = await ApiConnection.post("/JobbsprangetCoupon/CouponValidation", validateCoupon);        
      switch(paymentType){        
        case "1":
        setWeeklyCouponValidation(response.data?.couponDurationTxt);
        if(response.data.isSuccess)
          {
            setIsWeeklyCoupon(true);
            Mixpanel.track('YsfJobApp:ApplyCoupon:Weekly:'+user_email+"#"+coupon);
          }
        break;
      case "2":
        setMonthlyCouponValidation(response.data?.couponDurationTxt);
        if(response.data.isSuccess){
          setIsMonthlyCoupon(true);
          Mixpanel.track('YsfJobApp:ApplyCoupon:Monthly:'+user_email+"#"+coupon);
        }
        break;
      case "3":
        setQuaterlyCouponValidation(response.data?.couponDurationTxt);
        if(response.data.isSuccess){
          setIsQuaterlyCoupon(true);
          Mixpanel.track('YsfJobApp:ApplyCoupon:Quaterly:'+user_email+"#"+coupon);
        }
        break;
      default:
        setMonthlyCouponValidation(response.data?.couponDurationTxt);
        if(response.data.isSuccess){
          setIsMonthlyCoupon(true);
          Mixpanel.track('YsfJobApp:ApplyCoupon:Monthly:'+user_email+"#"+coupon);
        }
        break;        
    } 
   } 
   catch(err){     
      console.log("Error in ApplyCoupon!!");
    }
  } 

  const SetCouponVal= async (couponCode) => {
    switch(paymentType) 
    {      
      case "1": 
      if(couponCode.length===0)
        setWeeklyCouponValidation(""); 
        setWeeklyCoupon(couponCode);      
       break;  
      case "2": 
      if(couponCode.length===0)
        setMonthlyCouponValidation("");        
        setMonthlyCoupon(couponCode);
       break;  
      case "3": 
      if(couponCode.length===0)        
        setQuaterlyCouponValidation("");
        setQuaterlyCoupon(couponCode);
        break;     
       default:
    }
  } 

  const handleKeyDown = (event) => {
    console.log("event#"+event.key);    
    if (event.key === 'Enter')
        ApplyCoupon(); 
  }

  return (
    <div className="common-panel home-page">    
        <div className="home-container">
            <div className='subscription-container'>
            <h3 className='mb-5'>Supercharge your career</h3>          
              <NavLink to="/" className='backBttn'>
                <img src={IMAGE.back_Arrow} alt='back-arrow'/>&nbsp; Back
              </NavLink>                      

            <div className='row'>                
                <div className='col-lg-4' >
                    <div className={parseInt(subscriptionType) === 1 ? 'subscription-box active' : 'subscription-box'}  onClick={() => SubscribeType('1')}>
                    {parseInt(subscriptionType) === 1 && <label>Active</label>}
                       <h4>1 Week</h4>
                        <p>No cancellation fee.</p>
                        <span>€3.4 <sub>/week</sub></span>
                        <div className='coupon-form'>                            
                             <input type='text' maxLength="10" ref={weeklyCouponRef} value={weeklyCoupon} onChange={(e) => SetCouponVal(e.target.value.toUpperCase())}  onKeyDown={handleKeyDown} placeholder='COUPON CODE' className='form-control' />
                             <button className='ApplynowBtn' onClick={ApplyCoupon}>Apply now</button>
                        </div>  
                        <p>{weeklyCouponValidation}</p>                     
                        <button type="submit" onClick={btnWeeklyPaymentHandle} className='TrynowBtn' disabled={!stripeAll}>Subscribe</button>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className={parseInt(subscriptionType) === 2 ? 'subscription-box active' : 'subscription-box'}  onClick={() => SubscribeType('2')}>
                    {parseInt(subscriptionType) === 2 && <label>Active</label>}
                        <h4>4 Weeks</h4>
                        <p>No cancellation fee.</p>
                        <span>€2.9 <sub>/week</sub></span>
                        <div className='coupon-form'>                           
                            <input type='text' maxLength="10" ref={monthlyCouponRef} value={monthlyCoupon} onChange={(e) => SetCouponVal(e.target.value.toUpperCase())}  onKeyDown={handleKeyDown} placeholder='COUPON CODE' className='form-control' />
                            <button className='ApplynowBtn' ref={btnMonthlyCouponRef} onClick={ApplyCoupon}>Apply now</button>
                        </div>  
                        <p>{monthlyCouponValidation}</p>                     
                        <button type="submit" className='TrynowBtn' onClick={btnMonthlyPaymentHandle} disabled={!stripeAll}>Subscribe</button>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className={parseInt(subscriptionType) === 3 ? 'subscription-box active' : 'subscription-box'} onClick={() => SubscribeType('3')}>
                    {parseInt(subscriptionType) === 3 && <label>Active</label>}
                        <h4>12 Weeks</h4>
                        <label>Save 45%</label>
                        <p>No cancellation fee.</p>
                        <span>€1.9 <sub>/week</sub></span>
                        <div className='coupon-form'>
                             <input type='text' maxLength="10" ref={quaterlyCouponRef} value={quaterlyCoupon} onChange={(e) => SetCouponVal(e.target.value.toUpperCase())}  onKeyDown={handleKeyDown} placeholder='COUPON CODE' className='form-control' />
                             <button onClick={ApplyCoupon} className='ApplynowBtn'>Apply now</button>
                        </div> 
                        <p>{quaterlyCouponValidation}</p>                    
                        <button type="submit" className='TrynowBtn' onClick={btnQuaterlyPaymentHandle} disabled={false}>Subscribe</button>
                    </div>
                </div>                
            </div>
            <h3 style={{color:"green"}}>{subType}</h3>           
          </div>         
        </div>
    </div>
    )
}

export default Subscription